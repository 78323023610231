import React, { } from "react";
import { Link } from "react-router-dom";
import {
    Stack,
    Button,

} from '@chakra-ui/react';

export default function cadastro() {

    return (
        <>
            <h1> Atualização de Cadastro</h1>

            <Stack spacing={4} direction='row' align='center'>
                <Button colorScheme='pink' size='xs'>
                    Button
                </Button>
                <Button colorScheme='red' size='sm'>
                    Button
                </Button>
                <Button colorScheme='blue' size='md'>
                    Button
                </Button>
                <Button colorScheme='green' size='lg'>
                    Button
                </Button>
            </Stack>

        </>


    )
}
