import React from "react";

import { AuthProvider } from "./Auth";
import { UserProvider } from "./User";
import { OrderProvider } from "./Order";
import { PlanProvider } from "./Plan";

export const AppProvider = ({ children }) => {

    return (
        <AuthProvider>
            <UserProvider>
                <PlanProvider>
                    <OrderProvider>
                        {children}
                    </OrderProvider>
                </PlanProvider>
            </UserProvider>
        </AuthProvider>
    );

}