import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Text,
  Button,
  useDisclosure,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  Stack,
  RadioGroup,
  useToast,
  Textarea,
  Tooltip,
  Table,
  Th,
  Tr,
  Td,
  Thead,
  Tbody,
} from "@chakra-ui/react";
import { OrderContext } from "../../contexts/Order";

export default function FeedbackOrder() {
  const orderID = localStorage.getItem("orderID");
  const order = JSON.parse(localStorage.getItem("order"));
  const toast = useToast();
  const {
    feedbackQuestionsApi,
    sendFeedbackApi,
    getOrderFeedbackApi,
    showExpertOrderFeedbackApi,
  } = useContext(OrderContext);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [answers, setAnswers] = useState({});
  const [questions, setQuestions] = useState([]);
  const [positiveFeedback, setPositiveFeedback] = useState("");
  const [negativeFeedback, setNegativeFeedback] = useState("");
  const [feedbacksStatus, setFeedbacksStatus] = useState([]);
  const [expertFeedback, setExpertFeedback] = useState("");
  const [isExpertFeedbackModalOpen, setExpertFeedbackModalOpen] =
    useState(false);

  const userInfo = JSON.parse(localStorage.getItem("user"));

  async function getQuestions() {
    try {
      const response = await feedbackQuestionsApi();
      setQuestions(response.data?.[0]);
    } catch (error) {
      console.log(error);
    }
  }

  async function getFeedbacksStatus() {
    const orderID = localStorage.getItem("orderID");
    try {
      const response = await getOrderFeedbackApi(orderID);
      setFeedbacksStatus(response.data?.[0]);
      console.log(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getFeedbacksStatus();
    getQuestions();
  }, []);

  const handleAnswerChange = (question, value) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [question]: value, // Armazena a resposta da pergunta específica
    }));
  };

  const handleSubmit = async () => {
    const values = Object.entries(answers).map(([questionId, value]) => ({
      question_id: parseInt(questionId),
      value: parseInt(value),
    }));

    const data = {
      order_id: orderID,
      values,
      positives: positiveFeedback,
      negatives: negativeFeedback,
    };
    try {
      const inviteExpert = await sendFeedbackApi(data);
      if (inviteExpert.status === 200) {
        toast({
          title: "Feedback Enviado com Sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        onClose();
        setPositiveFeedback("");
        setNegativeFeedback("");
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
      toast({
        title: "Deu algo errado",
        status: "error",
        isClosable: true,
      });
    }
    // Aqui você pode realizar a lógica de enviar a resposta do questionário
    console.log("Resposta:", answers);
  };

  // Function to open the Expert Feedback Modal
  const openExpertFeedbackModal = () => {
    setExpertFeedbackModalOpen(true);
  };

  // Function to close the Expert Feedback Modal
  const closeExpertFeedbackModal = () => {
    setExpertFeedbackModalOpen(false);
  };
  async function showFeedbackUser(userID) {
    const orderID = localStorage.getItem("orderID");
    try {
      const response = await showExpertOrderFeedbackApi(userID, orderID);
      setExpertFeedback(response.data?.[0]);
      openExpertFeedbackModal();
      console.log(response.data?.[0]);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <Box
        bg={"#ffff"}
        borderRadius={"15px "}
        boxShadow={"0px 7px 16px 4px #EAEAEA"}
        p={5}
        ml={"180px"}
        maxWidth={"100%"}
      >
        <Flex pb={5} borderBottom={"1px solid #D9D9D9"}>
          <Box>Feedbacks </Box>
        </Flex>

        {order?.feedbackDone === 0 ? (
          userInfo.permission === "Revisor" ? (
            <>
              <Box mt={5}>
                <Button onClick={onOpen}>Realizar Feedback do Tradutor</Button>
              </Box>
            </>
          ) : userInfo.permission === "Editor" ? (
            <>
              <Box mt={5}>Realizar FeedBack Revisor</Box>
            </>
          ) : (
            ""
          )
        ) : userInfo.permission === "Tradutor" ? (
          " "
        ) : (
          <Text pt={5}> Você já enviou o feedback</Text>
        )}
      </Box>

      {feedbacksStatus !== null ? (
        <>
          <Box
            mt={5}
            bg={"#ffff"}
            borderRadius={"15px "}
            boxShadow={"0px 7px 16px 4px #EAEAEA"}
            p={5}
            ml={"180px"}
            maxWidth={"100%"}
          >
            <Table>
              <Thead>
                <Tr>
                  <Td>Expert</Td>
                  <Td>Status feedback</Td>
                  <Td>Feedback</Td>{" "}
                  {/* Adicionei um cabeçalho para a coluna de Feedback */}
                </Tr>
              </Thead>
              <Tbody>
                {feedbacksStatus.length > 0 ? (
                  feedbacksStatus.map((index) => (
                    <Tr key={index.id}>
                      {" "}
                      {/* <-- Opening <Tr> tag */}
                      <Td>
                        {index.translator === 1
                          ? "Tradutor"
                          : index.revisor === 1
                          ? "Revisor"
                          : ""}
                      </Td>
                      <Td>
                        {index.translator === 1
                          ? index.translator_feedback === 0
                            ? "Pendente"
                            : "Recebido"
                          : index.revisor === 1
                          ? index.revisor_feedback === 0
                            ? "Pendente"
                            : "Recebido"
                          : ""}
                      </Td>
                      {index.translator === 1 &&
                      (index.translator_feedback === 1 ||
                        (index.revisor === 1 &&
                          index.revisor_feedback === 1)) ? (
                        <Td>
                          <Button
                            onClick={() =>
                              showFeedbackUser(index.expert_id_receive_feedback)
                            }
                          >
                            Ver Feedback
                          </Button>
                        </Td>
                      ) : (
                        " "
                      )}
                    </Tr>
                  ))
                ) : (
                  <Tr>
                    {" "}
                    <Td>
                      {feedbacksStatus.translator === 1
                        ? "Tradutor"
                        : feedbacksStatus.revisor === 1
                        ? "Revisor"
                        : ""}
                    </Td>
                    <Td>
                      {feedbacksStatus.translator === 1
                        ? feedbacksStatus.translator_feedback === 0
                          ? "Pendente"
                          : "Recebido"
                        : feedbacksStatus.revisor === 1
                        ? feedbacksStatus.revisor_feedback === 0
                          ? "Pendente"
                          : "Recebido"
                        : ""}
                    </Td>
                    {/* Renderizar o botão "Ver Feedback" somente se translator_feedback ou revisor_feedback for igual a 1 */}
                    {feedbacksStatus.translator_feedback === 1 ||
                    feedbacksStatus.revisor_feedback === 1 ? (
                      <Td>
                        <Button
                          onClick={() =>
                            showFeedbackUser(
                              feedbacksStatus.expert_id_receive_feedback
                            )
                          }
                        >
                          Ver Feedback
                        </Button>
                      </Td>
                    ) : (
                      ""
                    )}
                  </Tr>
                )}
              </Tbody>
            </Table>
          </Box>
        </>
      ) : (
        // Renderizar um espaço reservado ou estado de carregamento enquanto aguarda os dados
        <Box mt={5}>Carregando feedbacks...</Box>
      )}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Questionário</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {Object.values(questions).map((question) => (
              <FormControl key={question.question_id} mb={4}>
                <Text fontWeight={"bold"}>{question.category}</Text>
                <FormLabel fontSize={"14px"}>{question.question}</FormLabel>
                <Tooltip label={question.help} aria-label="Ajuda">
                  <Text as="span" fontSize="sm" color="gray.500" ml={2} mb={5}>
                    info
                  </Text>
                </Tooltip>
                <RadioGroup
                  mt={5}
                  onChange={(value) =>
                    handleAnswerChange(question.question_id, value)
                  }
                  value={answers[question.question_id]}
                >
                  <Stack direction="row">
                    <Radio value="1">1</Radio>
                    <Radio value="2">2</Radio>
                    <Radio value="3">3</Radio>
                    <Radio value="4">4</Radio>
                    <Radio value="5">5</Radio>
                  </Stack>
                </RadioGroup>
              </FormControl>
            ))}
            <FormControl>
              <FormLabel>Pontos Positivos</FormLabel>
              <Textarea
                onChange={(e) => setPositiveFeedback(e.target.value)}
              ></Textarea>
            </FormControl>
            <FormControl>
              <FormLabel>Pontos Negativos</FormLabel>
              <Textarea
                onChange={(e) => setNegativeFeedback(e.target.value)}
              ></Textarea>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={handleSubmit}>
              Enviar Respostas
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {expertFeedback && (
        <Modal
          isOpen={isExpertFeedbackModalOpen}
          onClose={closeExpertFeedbackModal}
        >
          <ModalOverlay />
          <ModalContent width={"900px"} maxWidth={"100%"}>
            <ModalHeader>FeedBack Expert</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {/* Iterate through each feedback category/question */}
              {expertFeedback?.map((feedbackItem) => (
                <FormControl key={feedbackItem.id} mb={4}>
                  <Text fontWeight={"bold"}>{feedbackItem.question}</Text>
                  <Text fontSize={"14px"}>{feedbackItem.help}</Text>
                  <Text as="span" fontSize="sm" color="gray.500" ml={2} mb={5}>
                    info
                  </Text>
                  {/* Conditional rendering of RadioGroup */}
                  {feedbackItem.value !== null ? (
                    <RadioGroup
                      mt={5}
                      value={feedbackItem.value?.absolute_value_question.toString()} // Convert to string since RadioGroup expects a string value
                      isDisabled // Set the RadioGroup as disabled
                    >
                      <Stack direction="row">
                        <Radio value="1" isDisabled>
                          1
                        </Radio>
                        <Radio value="2" isDisabled>
                          2
                        </Radio>
                        <Radio value="3" isDisabled>
                          3
                        </Radio>
                        <Radio value="4" isDisabled>
                          4
                        </Radio>
                        <Radio value="5" isDisabled>
                          5
                        </Radio>
                      </Stack>
                    </RadioGroup>
                  ) : (
                    <Text>No feedback provided</Text>
                  )}
                </FormControl>
              ))}
            </ModalBody>
          </ModalContent>
          {/* ... Existing code ... */}
        </Modal>
      )}
    </>
  );
}
