import React, { useContext, useState, useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import {
  Box,
  Flex,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Button,
  Container,
} from "@chakra-ui/react";
import TeachingPills from "../../components/Prolab/TeachingPills";
import VideosProvatis from "../../components/Prolab/VideosProvatis"

import { BiLogoReact } from "react-icons/bi";
import { LuClipboardList, LuFilm, LuCoffee } from "react-icons/lu";
import { HiOutlineDocumentDuplicate } from "react-icons/hi";
import { FiBookOpen } from "react-icons/fi";



export default function Prolab() {

  return (
    <>
      <Navbar />

      <Container position={"absolute"} top={120} ml={270} minWidth={"80vw"}  >
        <Box bg={"#ffff"}
          borderRadius={"15px"}
          boxShadow={"0px 7px 16px 4px #EAEAEA"}
          w={'100%'}
          >
          <Flex display={"flex"} padding={5} alignItems="center">
            <Box display={'flex'} alignItems={'center'} >
              <Box marginRight={' 500px'}>
                <Text fontSize={"40px"} fontWeight={"600"}>
                  Prolab.
                </Text>
              </Box>
              <Box display={'flex'}>
                <BiLogoReact fontSize={"40px"}  />
                <LuClipboardList fontSize={"40px"} />
                <LuFilm fontSize={"40px"} />
              </Box>
            </Box>
          </Flex>
        </Box>

      </Container>
      <Box ml={300} maxWidth={"1000px"}>
          <Box p={2}>
            <Tabs variant='soft-rounded' colorScheme="green">
              <Box
                mt={'120px'}
                display={"block"}
                position={"absolute"}
                left={"285px"}
                backgroundColor={"#ffff"}
                p={8}
                borderRadius={"15px "}
                boxShadow={"0px 7px 16px 4px #EAEAEA"}
              >
                <Text>Guia do Prolab</Text>
                <TabList  display={"block"} borderBottom={"0"}>
                  <Tab> <LuCoffee/> Pílulas de ensino</Tab>
                  <Tab> <LuFilm /> Vídeos Provatis</Tab>
                  <Tab> <HiOutlineDocumentDuplicate/> Artigos Provatis</Tab>
                  <Tab> <FiBookOpen/> Manual Provatis</Tab>
                </TabList>
              </Box>

              <TabPanels>
                <TabPanel>
                  <TeachingPills/>
                </TabPanel>
                <TabPanel>
                  <VideosProvatis/>
                </TabPanel>
                <TabPanel>
                 
                </TabPanel>
                <TabPanel>
                 
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </Box>
    </>
  )

}

