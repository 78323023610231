import React, { useContext, useState, useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import {
  Box,
  Flex,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Button,
} from "@chakra-ui/react";
import DetailsOrder from "../../components/Order/DetailOrder";
import StepsOrder from "../../components/Order/StepOrder";
import { OrderContext } from "../../contexts/Order";
import DocumentsOrder from "../../components/Order/DocumentsOrder";
import FeedbackOrder from "../../components/Order/FeedbackOrder";
import DetailsOrderExpert from "../../components/Order/DetailOrderExpert";
import DocumentsOrderExpert from "../../components/Order/DocumentsOrderExpert";

export default function OrderExpert() {
  const { getOrderInfo } = useContext(OrderContext);
  const userInfo = JSON.parse(localStorage.getItem("user"));
  const [order, setOrder] = useState();
  const [stepBox, setStepBox] = useState(false);
  const [detailsBox, setDetailBox] = useState(false);

  async function getOrderInfos() {
    const orderID = localStorage.getItem("orderID");
    try {
      const orderInfoApi = await getOrderInfo(orderID);
      // console.log(orderInfoApi);
      setOrder(orderInfoApi.data?.[0]);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getOrderInfos();
  }, []);
  return (
    <>
      <Navbar />

      {order && (
        <Box ml={300} maxWidth={"1000px"}>
          <Box p={2}>
            <Tabs>
              <Box
                mt={4}
                display={"block"}
                position={"absolute"}
                left={"280px"}
                backgroundColor={"#ffff"}
                p={8}
                borderRadius={"15px "}
                boxShadow={"0px 7px 16px 4px #EAEAEA"}
              >
                <TabList display={"block"} borderBottom={"0"}>
                  <Tab>Detalhes</Tab>
                  <Tab>Documentos</Tab>

                  <Tab>Feedbacks</Tab>
                </TabList>
              </Box>

              <TabPanels>
                <TabPanel>
                  <DetailsOrderExpert />
                </TabPanel>
                <TabPanel>
                  <DocumentsOrderExpert />
                </TabPanel>
                <TabPanel>
                  <FeedbackOrder />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </Box>
      )}
    </>
  );
}
