import React, { useState } from "react";
import api from "../services/api";

export const UserContext = React.createContext({});

export const UserProvider = ({ children }) => {
  const listClientsApi = async () => {
    const token = localStorage.getItem("token");
    const response = await api.get("/list/clients", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  };

  const createClientApi = async (data) => {
    const token = localStorage.getItem("token");
    const response = await api.post("/create/client", data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=utf-8",
      },
    });
    return response;
  };

  const listExpertsApi = async (params) => {
    const token = localStorage.getItem("token");
    const response = await api.get(
      "/list/experts",
      { params },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  };

  const inviteExpertApi = async (data) => {
    const token = localStorage.getItem("token");
    const response = await api.post("/invite/expert", data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=utf-8",
      },
    });
    return response;
  };

  const listExpertsNotificationsApi = async () => {
    const token = localStorage.getItem("token");
    const response = await api.get("list/notifications/user", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  };

  const createExpertApi = async (data) => {
    const token = localStorage.getItem("token");
    const response = await api.post("/create/expert", data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=utf-8",
      },
    });
    return response;
  };

  const getExpertInfoApi = async(id) =>{
    const token = localStorage.getItem("token");
    const response = await api.get(`/show/expert/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      }, 
    })
    return response;

  }

  return (
    <UserContext.Provider
      value={{
        listClientsApi,
        createClientApi,
        listExpertsApi,
        inviteExpertApi,
        listExpertsNotificationsApi,
        createExpertApi,
        getExpertInfoApi,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
