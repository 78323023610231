import React, { useContext, useEffect, useState, useRef } from "react";
import api from "../../services/api";
import Navbar from "../../components/Navbar/Navbar";
import {
  useToast,
  Box,
  Flex,
  Button,
  FormControl,
  Input,
  Stack,
  Text,
  Spacer,
  Select,
  Link,
  Container,
  Icon,
  FormLabel,
} from "@chakra-ui/react";
import { UserContext } from "../../contexts/User";
import { PlanContext } from "../../contexts/Plan";
import JoditEditor from "../../components/Editor";
import { OrderContext } from "../../contexts/Order";
import { IoAddCircleOutline, IoTrashOutline } from "react-icons/io5";
import { AiOutlineUpload } from "react-icons/ai";

export default function CreateOrder() {
  const toast = useToast();
  const { listClientsApi } = useContext(UserContext);
  const { createOrderApi } = useContext(OrderContext);

  const [textAreaValue, setTextAreaValue] = useState("");
  const [clients, setClients] = useState([]);
  const [inputs, setInputs] = useState([]);
  const fileInputRefs = useRef([]);
  const [title, setTitle] = useState("");
  const [client, setClient] = useState("");
  const [typeDocument, setTypeDocument] = useState("");
  const [planID, setPlanID] = useState("");
  const [fastDelivery, setFastDelivery] = useState("");
  const [initialLanguage, setInitialLanguage] = useState("");
  const [finalLanguage, setFinalLanguage] = useState("");
  const [fastDeliveryDays, setFastDeliveryDays] = useState("");
  const [magazineName, setMagazineName] = useState("");
  const [magazineEdit, setMagazineEdit] = useState("");
  const [wordsNumberOriginal, setWordsNumberOriginal] = useState("");
  const [wordsNumberActual, setWordsNumberActual] = useState("");
  const [totalReference, setTotalReference] = useState("");
  const [figures, setFigures] = useState("");
  const [areaID, setAreaID] = useState("");
  const [keywords, setKeywords] = useState([]);
  const [description, setDescription] = useState("");
  const [translated, setTranslated] = useState();
  const [services, setServices] = useState([]);
  const [aditionalServices, setAditionalServices] = useState(0);
  const [discount, setDiscount] = useState(0);

  const handleInputTitle = (e) => setTitle(e.target.value);
  const handleInputClientID = (e) => setClient(e.target.value);

  const handleInputTypeDocument = (e) => setTypeDocument(e.target.value);
  const handleInputPlanID = (e) => {
    setPlanID(e.target.value);
    if (e.target.value == 3) {
      setMagazineEdit(1);
    }
  };
  const handleInputInitialLanguage = (e) => setInitialLanguage(e.target.value);
  const handleInputFinalLanguage = (e) => setFinalLanguage(e.target.value);
  const handleInputFastDelivery = (e) => setFastDelivery(e.target.value);
  const handleInputFastDeliveryDays = (e) =>
    setFastDeliveryDays(e.target.value);
  const handleInputMagazineName = (e) => setMagazineName(e.target.value);
  const handleInputMagazineEdit = (e) => setMagazineEdit(e.target.value);
  const handleInputWordsOriginal = (e) =>
    setWordsNumberOriginal(e.target.value);
  const handleInputWordsActual = (e) => setWordsNumberActual(e.target.value);
  const handleInputFigures = (e) => setFigures(e.target.value);
  const handleInputTotalReference = (e) => setTotalReference(e.target.value);
  const handleInputAreaID = (e) => setAreaID(e.target.value);
  const handleTranslated = (e) => setTranslated(e.target.value);
  const handleInputKeywords = (e) => {
    const array = e.target.value.replace(";", "").split(" ");
    setKeywords(array);
  };

  const handleInputDescription = (e) => {
    setDescription(e);
  };

  const handleBlurAreaChange = (content) => {
    setTextAreaValue(content);
  };

  const adicionarInput = () => {
    setInputs([...inputs, { type: "" }]);
  };

  const handleChange = (index) => (event) => {
    const newInputs = [...inputs];
    newInputs[index].file = event.target.files[0];
    setInputs(newInputs);
  };

  const excluirInput = (index) => {
    const newInputs = [...inputs];
    newInputs.splice(index, 1);
    setInputs(newInputs);
  };

  async function listClients() {
    try {
      const clients = await listClientsApi();
      setClients(clients.data?.[0]);
    } catch (error) {
      console.log(error);
    }
  }

  async function createOrder() {
    if (inputs.length == 0) {
      alert(
        "Você não anexou nenhum documento, anexe um documento para adicionar o serviço"
      );
    } else {
      try {
        const requestData = {
          title: title,
          client_id: client,
          abstract: textAreaValue,
          type_document: typeDocument,
          plan_id: planID,
          fast_delivery: fastDelivery,
          language_origin: initialLanguage,
          language_final: finalLanguage,
          fast_delivery: fastDelivery,
          fast_delivery_days: fastDeliveryDays,
          fast_delivery_magazine: 0,
          fast_delivery_days_magazine: 0,
          
          magazine_name: magazineName,
          magazine_edit: magazineEdit,
          words_number_original: wordsNumberOriginal,
          total_reference: totalReference,
          qty_figure: figures,
          services: services,
          aditional_services: aditionalServices,
          area_id: areaID,
          keywords: keywords,
          discount: discount,
          description: description,
          translated: translated,
        };

        const responseData = await createOrderApi(requestData);

        // Extrair o ID da order da resposta da API
        const orderId = responseData.data?.[0].id;
        const userId = responseData.data?.[0].user_id;

        console.log(responseData);

        await Promise.all(
          inputs.map(async (input) => {
            if (input) {
              const formData = new FormData();
              formData.append("file", input.file);
              formData.append("order_id", orderId);
              formData.append("user_id", userId);

              const token = localStorage.getItem("token");
              const fileUploadResponse = await api.post(
                "/upload/client/file",
                formData,
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data",
                  },
                }
              );

              // Adicione o resultado do upload ao array fileUploadResponses
              return fileUploadResponse.data;
            } else {
              // Retorne undefined se nenhum arquivo for selecionado
              return undefined;
            }
          })
        );

        if (responseData.status === 200) {
          // Faça algo com a resposta do servidor
          toast({
            title: "Serviço Criado com Sucesso",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }

        if (responseData.status === 202) {
          // Faça algo com a resposta do servidor
          toast({
            title:
              "Serviço Criado, mas precisará selecionar o expert manualmente",
            status: "warning",
            duration: 7000,
            isClosable: true,
          });
        }

        console.log(responseData);
        // Redirecione para a página de serviços
        window.location.pathname = "/servicos";
      } catch (error) {
        toast({
          title:
            "Não foi possível criar o serviço, verifique os campos e tente novamente",
          status: "error",
          duration: 7000,
          isClosable: true,
        });
      }
    }
  }

  useEffect(() => {
    listClients();
  }, []);

  useEffect(() => {
    fileInputRefs.current = fileInputRefs.current.slice(0, inputs.length);
  }, [inputs]);

  return (
    <>
      <Navbar />

      <Container position="absolute" top={120} ml={300} minWidth={"75vw"}>
        <Box
          bg="#ffff"
          borderRadius="15px"
          boxShadow="0px 7px 16px 4px #EAEAEA"
        >
          <Flex display="flex" padding={5} alignItems="center">
            <Box>
              <Text fontSize="24px" fontWeight="600">
                Adicionar um novo Serviço
              </Text>
            </Box>

            <Spacer />
          </Flex>
        </Box>

        <Flex
          width="100%"
          display="flex"
          justifyContent="center"
          marginTop="45px"
        >
          <Box
            marginBottom={5}
            boxShadow="xl"
            padding={35}
            borderRadius={15}
            backgroundColor="white"
          >
            <Box>
              <Stack marginTop="15px" marginBottom="15px">
                <Text fontSize="lg">Detalhes</Text>
              </Stack>
              <FormControl display="inline-flex">
                {clients && (
                  <Select
                    onChange={handleInputClientID}
                    placeholder="Selecione um cliente"
                  >
                    {clients.map((index) => (
                      <option key={index.userID} value={index.userID}>
                        {index.name}
                      </option>
                    ))}
                  </Select>
                )}
                <Select
                  onChange={handleInputPlanID}
                  placeholder="Selecione um plano"
                  marginLeft="1%"
                >
                  <option value={1}>Basic</option>
                  <option value={2}>Pro</option>
                  <option value={3}>Full</option>
                </Select>
                <Select
                  onChange={handleInputTypeDocument}
                  placeholder="Selecione tipo de serviço"
                  marginLeft="1%"
                >
                  <option value="Científico">Científico</option>
                  <option value="Manual">Manual</option>
                </Select>
                <Input
                  type="Number"
                  onChange={handleInputWordsOriginal}
                  placeholder="Numero de palavras"
                  marginLeft="1%"
                />
              </FormControl>
              <FormControl marginTop="1%" display="inline-flex">
                <Select
                  placeholder="FastDelivery"
                  onChange={handleInputFastDelivery}
                >
                  <option value="1">Sim</option>
                  <option value="0">Não</option>
                </Select>

                {fastDelivery === "1" ? (
                  <Input
                    onChange={handleInputFastDeliveryDays}
                    marginLeft="1%"
                    placeholder="Receber em quantos dias"
                  />
                ) : null}
                <Input
                  onChange={handleInputTotalReference}
                  marginLeft="1%"
                  placeholder="Numero de referências"
                />
                <Input
                  onChange={handleInputFigures}
                  marginLeft="1%"
                  placeholder="Quantidade de figuras"
                />
              </FormControl>

              <Stack marginTop="50px">
                <Text fontSize="lg">Serviço Adicional</Text>
              </Stack>
              <FormControl marginTop="2%">
                <FormLabel>Edição das normas da Revista</FormLabel>
                <Select
                  display="inline-block"
                  width={"40%"}
                  value={magazineEdit}
                  placeholder="Edição das normas da Revista"
                  onChange={handleInputMagazineEdit}
                >
                  <option value="1">Sim</option>
                  <option value="0">Não</option>
                </Select>
                {magazineEdit == 1 ? (
                  <Input
                    display="inline-block"
                    marginLeft="1%"
                    width={"40%"}
                    placeholder="Nome da Revista"
                    onChange={handleInputMagazineName}
                  ></Input>
                ) : (
                  ""
                )}
              </FormControl>

              <Stack marginTop="50px">
                <Text fontSize="lg">Serviço</Text>
              </Stack>
              <FormControl width={"35%"} marginTop="15px">
                <Select
                  required
                  value={translated}
                  placeholder="O serviço está traduzido ?"
                  onChange={handleTranslated}
                >
                  <option value="1">Sim</option>
                  <option value="0">Não</option>
                </Select>
              </FormControl>
              <FormControl display="inline-flex" marginTop="2%">
                <Input onChange={handleInputTitle} placeholder="Titulo" />

                <Select
                  onChange={handleInputInitialLanguage}
                  placeholder="Idioma de Origem"
                  marginLeft="1%"
                >
                  <option value="PT-BR">Português(BR)</option>
                  <option value="EN">Inglês</option>
                </Select>

                <Select
                  onChange={handleInputFinalLanguage}
                  placeholder="Idioma Final"
                  marginLeft="1%"
                >
                  <option value="PT-BR">Português(BR)</option>
                  <option value="EN">Inglês</option>
                </Select>
              </FormControl>
              <Stack margin="15px 0">
                <Text fontSize="md">Abstract</Text>
              </Stack>
              <FormControl>
                <JoditEditor
                  onBlur={handleBlurAreaChange}
                  value={textAreaValue}
                />
              </FormControl>

              <FormControl marginTop="2%" display="inline-flex">
                <Input
                  onChange={handleInputKeywords}
                  marginLeft="2%"
                  placeholder="Digite as palavras chave"
                />
              </FormControl>
            </Box>

            <Box>
              <Stack marginTop="50px" marginBottom="15px">
                <Text fontSize="lg">Arquivo</Text>
              </Stack>
              <Button
                onClick={adicionarInput}
                bg="white"
                border="1px"
                borderStyle="dashed"
                borderColor="#DBDBDB"
                height="56px"
                marginTop="25px"
              >
                <Icon fontSize={25} color="blue">
                  <IoAddCircleOutline />
                </Icon>

                <Box display="inline-block" marginLeft={2}>
                  <Text fontSize={12} fontWeight={600}>
                    Deseja enviar mais um documento?
                  </Text>
                  <Text fontSize={14} fontWeight={400}>
                    Clique para adicionar mais um arquivo
                  </Text>
                </Box>
              </Button>
              {inputs.map((input, index) => (
                <Box display="flex" marginTop="15px" key={index}>
                  <Box display="flex">
                    <Box marginRight="20px">
                      <Text fontSize={12}>
                        Documento - Envie arquivos nas extensões de docx, doc e
                        PDF.
                      </Text>
                      <Input
                        type="file"
                        onChange={handleChange(index)}
                        ref={(el) => (fileInputRefs.current[index] = el)}
                      />
                    </Box>
                    <Box marginRight="20px">
                      <Text fontSize={12}>Tipo do documento</Text>
                      <Select
                        width="205px"
                        value={input.type}
                        onChange={(e) => {
                          const newInputs = [...inputs];
                          newInputs[index].type = e.target.value;
                          setInputs(newInputs);
                        }}
                      >
                        <option value="Original">Original</option>
                        <option value="Consulta">Consulta</option>
                      </Select>
                    </Box>
                    <Box display="flex" alignItems="center">
                      <Icon
                        onClick={() => excluirInput(index)}
                        fontSize={25}
                        color="red"
                        cursor="pointer"
                      >
                        <IoTrashOutline />
                      </Icon>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>

            <Stack marginTop="50px">
              <Text fontSize="lg">Expert</Text>
            </Stack>

            <Stack margin="15px 0">
              <Text fontSize="md">Descrição (para os experts)</Text>
            </Stack>
            <FormControl>
              <JoditEditor
                onBlur={handleInputDescription}
                value={description}
              />
            </FormControl>

            <Box marginTop="2%">
              <Button margin="2%">
                <Link to="/servicos">Voltar</Link>
              </Button>

              <Button colorScheme="blue" onClick={createOrder}>
                Salvar
              </Button>
            </Box>
          </Box>
        </Flex>
      </Container>
    </>
  );
}
