import React, { useState, useEffect } from "react";
import api from "../../services/api";
import {
  Box,
  Icon,
  Input,
  Select,
  Text,
  Button,
  Link,
  Stack,
  Flex,
  useToast,
} from "@chakra-ui/react";
import { IoAddCircleOutline, IoTrashOutline } from "react-icons/io5";

export default function DocumentsOrderExpert() {
  const toast = useToast();
  const [inputs, setInputs] = useState([]);
  const [docsTradutor, setDocsTradutor] = useState(null);
  const [docsRevisor, setDocsRevisor] = useState(null);
  const [docsEditor, setDocsEditor] = useState(null);
  const [docsClient, setDocsClient] = useState(null);

  const addInput = () => {
    setInputs([
      ...inputs,
      { version: "Versão 1", subtype: "Texto", file: null },
    ]);
  };

  const removeInput = (index) => {
    const newInputs = [...inputs];
    newInputs.splice(index, 1);
    setInputs(newInputs);
  };

  const handleInputChange = (index, property, value) => {
    const newInputs = [...inputs];
    newInputs[index] = {
      ...newInputs[index],
      [property]: value,
    };
    setInputs(newInputs);
  };

  const handleFileChange = (index, event) => {
    const file = event.target.files[0];
    const newInputs = [...inputs];
    newInputs[index].file = file;
    setInputs(newInputs);
  };

  async function getDocumentsOrder() {
    const orderID = localStorage.getItem("orderID");
    const token = localStorage.getItem("token");
    try {
      const response = await api.get(`list/documents/order/${orderID}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setDocsTradutor(response.data?.[1]?.["tradutor"]);
      setDocsRevisor(response.data?.[1]?.["revisor"]);
      setDocsEditor(response.data?.[1]?.["editor"]);
      setDocsClient(response.data?.[1]?.["client"]);
    } catch (error) {
      console.error(error);
    }
  }

  const handleDocumentSubmit = async (index) => {
    const input = inputs[index];

    if (!input.file) {
      toast({
        title: "Selecione um arquivo válido.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    const orderID = localStorage.getItem("orderID");
    const token = localStorage.getItem("token");
    const formData = new FormData();
    formData.append("order_id", orderID);
    formData.append("version", input.version);
    formData.append("subtype", input.subtype);
    formData.append("file", input.file);
    try {
      await api.post("upload/expert/file", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      toast({
        title: "Upload feito com sucesso!",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      removeInput(index);
      getDocumentsOrder();
    } catch (error) {
      console.error(error);
      toast({
        title: "Não foi possível enviar o documento. Tente novamente.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const truncateText = (text) => {
    if (text.length > 20) {
      return text.substring(0, 20) + "...";
    }
    return text;
  };

  useEffect(() => {
    getDocumentsOrder();
  }, []);

  return (
    <>
      <Box
        bg={"#ffff"}
        borderRadius={"15px "}
        boxShadow={"0px 7px 16px 4px #EAEAEA"}
        p={5}
        ml={"180px"}
        maxWidth={"100%"}
      >
        <Flex pb={5} borderBottom={"1px solid #D9D9D9"}>
          <Box>Documentos cliente</Box>
        </Flex>
        {docsClient && (
          <Box
            mt={5}
            bg={"#ffff"}
            borderRadius={"15px "}
            boxShadow={"0px 7px 16px 4px #EAEAEA"}
            p={5}
            ml={"180px"}
            maxWidth={"100%"}
          >
            {docsClient?.map((index) => (
              <Box mt={2} fontSize={"14px"}>
                <Box display={"inline-flex"}>
                  <Text fontWeight={"light"}>Documento: </Text>
                  <Link
                    ml={2}
                    fontWeight={"bold"}
                    href={
                      "https://api.provatis.academy/public/uploads/expert/" +
                      index.filename
                    }
                    target="_blank"
                  >
                    {truncateText(index.filename)}
                  </Link>
                </Box>
              </Box>
            ))}
          </Box>
        )}
      </Box>

      <Box
        mt={5}
        bg={"#ffff"}
        borderRadius={"15px "}
        boxShadow={"0px 7px 16px 4px #EAEAEA"}
        p={5}
        ml={"180px"}
        maxWidth={"100%"}
      >
        <Stack>
          <Text>Adicionar Arquivos</Text>
        </Stack>
        <Button
          onClick={addInput}
          bg={"white"}
          border={"1px"}
          borderStyle={"dashed"}
          borderColor={"#DBDBDB"}
          height={"56px"}
          marginTop={"25px"}
        >
          <Icon fontSize={25} color={"blue"}>
            <IoAddCircleOutline />
          </Icon>

          <Box display={"inline-block"} marginLeft={2}>
            <Text fontSize={12} fontWeight={600}>
              Deseja enviar mais um documento?
            </Text>
            <Text fontSize={14} fontWeight={400}>
              Clique para adicionar mais um arquivo
            </Text>
          </Box>
        </Button>
        {inputs.map((input, index) => (
          <Box display={"flex"} alignItems={"center"} key={index}>
            <Box marginRight={"20px"} mt={5} width={"350px"} maxWidth={"100%"}>
              <Input
                pt={2}
                fontSize={"12px"}
                type="file"
                accept=".pdf, .doc, .docx"
                onChange={(event) => handleFileChange(index, event)}
              />
            </Box>
            <Box marginRight={"20px"} mt={5}>
              <Select
                width="115x"
                value={input.version}
                onChange={(event) =>
                  handleInputChange(index, "version", event.target.value)
                }
              >
                <option value={"Versão 1"}>Versão 1</option>
                <option value={"Versão 2"}>Versão 2</option>
                <option value={"Versão 3"}>Versão 3</option>
                <option value={"Versão 4"}>Versão 4</option>
                <option value={"Versão 5"}>Versão 5</option>
                <option value={"Versão 6"}>Versão 6</option>
                <option value={"Versão 7"}>Versão 7</option>
                <option value={"Versão 8"}>Versão 8</option>
                <option value={"Versão 9"}>Versão 9</option>
                <option value={"Versão 10"}>Versão 10</option>
                <option value={"Versão 11"}>Versão 11</option>
                <option value={"Versão 12"}>Versão 12</option>
                <option value={"Versão 13"}>Versão 13</option>
                <option value={"Versão 14"}>Versão 14</option>
                <option value={"Versão 15"}>Versão 15</option>
                <option value={"Clean Copy"}>Clean Copy</option>
              </Select>
            </Box>
            <Box mt={5} mr={2} width={"100px"}>
              <Select
                value={input.subtype}
                onChange={(event) =>
                  handleInputChange(index, "subtype", event.target.value)
                }
              >
                <option value="Texto">Texto</option>
                <option value="Carta de Apresentação">
                  Carta de apresentação
                </option>
              </Select>
            </Box>
            <Box mt={5}>
              <Button onClick={() => handleDocumentSubmit(index)}>
                Enviar
              </Button>
            </Box>
            <Box mt={5}>
              <Icon
                as={IoTrashOutline}
                onClick={() => removeInput(index)}
                fontSize={25}
                color="red"
                cursor="pointer"
              />
            </Box>
          </Box>
        ))}
      </Box>

      {docsTradutor && (
        <Box
          mt={5}
          bg={"#ffff"}
          borderRadius={"15px "}
          boxShadow={"0px 7px 16px 4px #EAEAEA"}
          p={5}
          ml={"180px"}
          maxWidth={"100%"}
        >
          Documentos Revisor
          {docsTradutor?.map((index) => (
            <Box mt={2} fontSize={"14px"}>
              <Box display={"inline-flex"}>
                <Text fontWeight={"light"}>Documento: </Text>
                <Link
                  ml={2}
                  fontWeight={"bold"}
                  href={
                    "https://api.provatis.academy/public/uploads/expert/" +
                    index.filename
                  }
                  target="_blank"
                >
                  {truncateText(index.filename)}
                </Link>
              </Box>

              <Box display={"inline-flex"} ml={5}>
                <Text fontWeight={"light"}>Versão: </Text>
                <Text ml={2} fontWeight={"bold"}>
                  {" "}
                  {index.version}
                </Text>
              </Box>

              <Box display={"inline-flex"} ml={5}>
                <Text fontWeight={"light"}>Subtipo: </Text>
                <Text ml={2} fontWeight={"bold"}>
                  {" "}
                  {index.subtype}
                </Text>
              </Box>
            </Box>
          ))}
        </Box>
      )}
      {docsRevisor && (
        <Box
          mt={5}
          bg={"#ffff"}
          borderRadius={"15px "}
          boxShadow={"0px 7px 16px 4px #EAEAEA"}
          p={5}
          ml={"180px"}
          maxWidth={"100%"}
        >
          Documentos Tradutor
          {docsRevisor?.map((index) => (
            <Box mt={2} fontSize={"14px"}>
              <Box display={"inline-flex"}>
                <Text fontWeight={"light"}>Documento: </Text>
                <Link
                  ml={2}
                  fontWeight={"bold"}
                  href={
                    "https://api.provatis.academy/public/uploads/expert/" +
                    index.filename
                  }
                >
                  {truncateText(index.filename)}
                </Link>
              </Box>

              <Box display={"inline-flex"} ml={5}>
                <Text fontWeight={"light"}>Versão: </Text>
                <Text ml={2} fontWeight={"bold"}>
                  {" "}
                  {index.version}
                </Text>
              </Box>

              <Box display={"inline-flex"} ml={5}>
                <Text fontWeight={"light"}>Subtipo: </Text>
                <Text ml={2} fontWeight={"bold"}>
                  {" "}
                  {index.subtype}
                </Text>
              </Box>
            </Box>
          ))}
        </Box>
      )}
      {docsEditor && (
        <Box
          mt={5}
          bg={"#ffff"}
          borderRadius={"15px "}
          boxShadow={"0px 7px 16px 4px #EAEAEA"}
          p={5}
          ml={"180px"}
          maxWidth={"100%"}
        >
          Documentos Editor
          {docsEditor?.map((index) => (
            <Box mt={2} fontSize={"14px"}>
              <Box display={"inline-flex"}>
                <Text fontWeight={"light"}>Documento: </Text>
                <Link
                  ml={2}
                  fontWeight={"bold"}
                  href={
                    "https://api.provatis.academy/public/api/uploads/expert/" +
                    index.filename
                  }
                >
                  {truncateText(index.filename)}
                </Link>
              </Box>

              <Box display={"inline-flex"} ml={5}>
                <Text fontWeight={"light"}>Versão: </Text>
                <Text ml={2} fontWeight={"bold"}>
                  {" "}
                  {index.version}
                </Text>
              </Box>

              <Box display={"inline-flex"} ml={5}>
                <Text fontWeight={"light"}>Subtipo: </Text>
                <Text ml={2} fontWeight={"bold"}>
                  {" "}
                  {index.subtype}
                </Text>
              </Box>
            </Box>
          ))}
        </Box>
      )}
    </>
  );
}
