import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import { useNavigate } from "react-router-dom";
import { Box, Icon, Avatar, Container, Flex, Text } from "@chakra-ui/react";
import { TbHandRock } from "react-icons/tb";
import { HiOutlineLightningBolt } from "react-icons/hi";
import { LuFlaskConical } from "react-icons/lu";
import { IoTimeOutline, IoChevronForward } from "react-icons/io5";
import Logo from "../../images/avatar.jpg";
import { FaFlask } from "react-icons/fa";
export default function Home() {
  const navigate = useNavigate();
  const [user, setUser] = useState();
  const [userHistoric, setUserHistoric] = useState(null);

  function getExpertInfo() {
    const userInfo = JSON.parse(localStorage.getItem("user"));

    //  console.log(userInfo);
    setUser(userInfo);
    setUserHistoric(userInfo.historic?.[0]);
    if (userInfo.historic && userInfo.historic.length > 0) {
      setUserHistoric(userInfo.historic[0]);
    }
  }

  function handleOrderPage(index) {
    localStorage.setItem("orderID", index.id);
    if (user.role_id === 1) {
      navigate(`/servico/${index.title}`);
    } else {
      navigate(`/expert/servico/${index.title}`);
    }
  }

  useEffect(() => {
    getExpertInfo();
  }, []);
  return (
    <>
      <Navbar />
      {user && (
        <Container
          position={"absolute"}
          top={150}
          ml={300}
          minWidth={"80vw"}
          pb={8}
        >
          <Box
            boxShadow={"0px 8px 16px -10px"}
            width={"90%"}
            borderRadius={"8px"}
          >
            <Box
              backgroundColor={"#00FF94"}
              minHeight={"80px"}
              borderRadius={"8px 8px 0 0"}
            >
              <Flex alignItems={"center"} flexDirection={"row"}>
                <Box padding={5}>
                  <Avatar
                    position={"absolute"}
                    left={"45px"}
                    top={"40px"}
                    size={"lg"}
                    src={Logo}
                  />
                </Box>
                <Box ml={"100px"} mt={"14px"} fontSize={"16px"}>
                  <Text>
                    Bem Vindo,<br></br>
                  </Text>
                  <Text fontWeight={"bold"}>{user.name}</Text>
                </Box>
                <Box ml={"240px"} mt={4}>
                  <Box
                    backgroundColor={"white"}
                    marginLeft={5}
                    width={"35px"}
                    height={"35px"}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    borderRadius={"50%"}
                  >
                    <Icon color={"#00FF94"} fontSize={22}>
                      <TbHandRock />
                    </Icon>
                  </Box>
                </Box>
              </Flex>
            </Box>
            <Flex
              backgroundColor={"white"}
              minHeight={"80px"}
              borderRadius={" 0 0 8px 8px"}
            >
              <Box></Box>
            </Flex>
          </Box>

          <Flex>
            <Box
              backgroundColor={"white"}
              mt={5}
              boxShadow={"0px 8px 16px -10px"}
              width={"62% "}
              borderRadius={"8px"}
              height={"250px"}
            >
              <Flex
                alignItems={"center"}
                p={5}
                justifyContent={"space-between"}
                borderBottom={"1px solid rgba(210, 209, 209, 0.4)"}
              >
                <Box display={"inline-flex"} alignItems={"center"}>
                  <FaFlask />
                  <Text ml={5}>Prolab</Text>
                </Box>
                <Box
                  display={"inline-flex"}
                  alignItems={"center"}
                  justifyContent={"end"}
                >
                  <Text ml={5}>Ir para o Prolab</Text>
                </Box>
              </Flex>
            </Box>

            <Box
              backgroundColor={"white"}
              ml={"3%"}
              mt={5}
              boxShadow={"0px 8px 16px -10px"}
              width={"25%"}
              borderRadius={"8px"}
            >
              <Flex display={"block"} alignItems={"end"}>
                <Box
                  backgroundColor={"#00FF94"}
                  width={"100%"}
                  height={"90px"}
                  borderTopRadius={"8px"}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Flex
                    justifyContent={"center"}
                    alignItems={"end"}
                    backgroundColor={"white"}
                    display={"flex"}
                    borderRadius={"100%"}
                    border={"1px"}
                    borderColor={"#E7E9F4"}
                    marginBottom={"-80px"}
                  >
                    <Box padding={"20px"} color={"#8389AA"} fontSize={"30px"}>
                      <LuFlaskConical />
                    </Box>
                  </Flex>
                </Box>
                {user.actual_service != null ? (
                  <>
                    <Box
                      marginTop={"50px"}
                      marginLeft={"10px"}
                      marginRight={"10px"}
                    >
                      <Text
                        backgroundColor={"#853AFF"}
                        borderRadius={"100px"}
                        width={"100px"}
                        color={"white"}
                        fontSize={"12px"}
                        fontWeight={600}
                        display={"flex"}
                        justifyContent={"center"}
                        height={"30px"}
                        alignItems={"center"}
                      >
                        {userHistoric.status}
                      </Text>
                      <Text
                        marginTop={"15px"}
                        fontSize={"16px"}
                        fontWeight={500}
                      >
                        {user.actual_service?.title}
                      </Text>
                      <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        marginTop={"10px"}
                      >
                        <Box
                          backgroundColor={"black"}
                          borderRadius={"100px"}
                          width={"60px"}
                          display={"flex"}
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          <Icon color={"#00FF94"} fontSize={20}>
                            <HiOutlineLightningBolt />
                          </Icon>
                          <Text
                            fontSize={"12px"}
                            fontWeight={600}
                            color={"white"}
                          >
                            {user.actual_service?.plan_id === 1
                              ? "Basic"
                              : user.actual_service?.plan_id === 2
                              ? "PRO"
                              : user.actual_service?.plan_id === 3
                              ? "FULL"
                              : null}
                          </Text>
                        </Box>
                        <Box
                          display={"flex"}
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          <Icon color={"#00FF94"} fontSize={20}>
                            <FaFlask />
                          </Icon>
                          <Text fontSize={"16px"} fontWeight={500}>
                            {user.actual_service?.type_document}
                          </Text>
                        </Box>
                      </Box>
                      <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        marginTop={"20px"}
                        borderRadius={"100px"}
                        backgroundColor={"#F5F6FF"}
                      >
                        <Icon color={"#00FF94"} fontSize={20}>
                          <IoTimeOutline />
                        </Icon>
                        <Text color={"#565656"} fontSize={"12px"}>
                          {" "}
                          <b>Prazo Final:</b> {userHistoric.deadline}
                        </Text>
                      </Box>

                      <Box
                        marginTop={"20px"}
                        borderTop={"1px"}
                        borderColor={"#D2D1D1"}
                      >
                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          marginTop={"5px"}
                        >
                          <Text fontSize={"14px"} fontWeight={300}>
                            Fast Delivery
                          </Text>
                          <Text fontSize={"14px"} fontWeight={500}>
                            {user.actual_service?.fastdelivery === 0
                              ? "NÃO"
                              : "SIM"}
                          </Text>
                        </Box>
                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          marginTop={"5px"}
                        >
                          <Text fontSize={"14px"} fontWeight={300}>
                            Idioma origem
                          </Text>
                          <Text fontSize={"14px"} fontWeight={500}>
                            {user.actual_service?.language_origin === "br" ||
                            user.actual_service?.language_origin === "pt"
                              ? "Português"
                              : "Inglês"}
                          </Text>
                        </Box>
                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          marginTop={"5px"}
                        >
                          <Text fontSize={"14px"} fontWeight={300}>
                            Idioma final
                          </Text>
                          <Text fontSize={"14px"} fontWeight={500}>
                            {user.actual_service?.language_final === "br" ||
                            user.actual_service?.language_final === "pt"
                              ? "Português"
                              : "Inglês"}
                          </Text>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      marginTop={"30px"}
                      paddingLeft={"10px"}
                      color={"white"}
                      width={"100%"}
                      height={"60px"}
                      borderRadius={"0 0 8px 8px"}
                      backgroundColor={"Black"}
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      cursor={"pointer"}
                      onClick={() => handleOrderPage(user.actual_service)}
                    >
                      <Text>Ir para serviço </Text>
                      <Icon color={"white"} fontSize={"20px"}>
                        <IoChevronForward />
                      </Icon>
                    </Box>
                  </>
                ) : (
                  <Text p={8}> Sem Serviço em Andamento</Text>
                )}
              </Flex>
            </Box>
          </Flex>
        </Container>
      )}
    </>
  );
}
