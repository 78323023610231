import React, { useState, useContext, useEffect } from "react";
import JoditEditor from "../../components/Editor";
import {
    Box,
    Button,
    Text,
    Input,
    Stack,
    FormControl,
    Tabs,
    Tab,
    TabList,
    AspectRatio,
} from "@chakra-ui/react";
import { OrderContext } from "../../contexts/Order";
import { AiOutlineDislike, AiOutlineLike } from "react-icons/ai";

export default function TeachingPills() {
    const [title, setTitle] = useState("");
    const [video, setVideo] = useState("");
    const [description, setDescription] = useState("");
    const [savedInfos, setSavedInfos] = useState([]);
    const [categories, setCategories] = useState("")
    const { feedbackCategoriesApi } = useContext(OrderContext);

    async function getCategoriesApi(id) {

        try {
            const response = await feedbackCategoriesApi(id);
            console.log(response.data?.[0]);
            setCategories(response.data?.[0]);
        } catch (error) {
            console.log(error);
        }
    }

    const handleTitleChange = (event) => {
        setTitle(event.target.value);
    };

    const handleDescriptionChange = (content) => {
        setVideo(content);
    };
    const handleVideoChange = (content) => {
        setVideo(content);
    };

    const saveInfo = () => {
        setSavedInfos([...savedInfos, { title: title, description: description }]);
        setTitle("");
        setDescription("");
        setVideo("");
    };

    useEffect(() => {
        getCategoriesApi()
    }, [categories]);

    return (
        <>
            {categories && (
                <>

                    <Tabs variant='soft-rounded' colorScheme="green">
                        <Box
                            justifyContent={'center'}
                            mt={'370px'}
                            w={'250px'}
                            display={"block"}
                            position={"absolute"}
                            left={"285px"}
                            backgroundColor={"#ffff"}
                            p={8}
                            borderRadius={"15px "}
                            boxShadow={"0px 7px 16px 4px #EAEAEA"}
                        >
                            <Text>Categorias</Text>

                            <TabList display={"block"} borderBottom={"0"}>
                                {categories.map((index) => (
                                    <Tab> {index.category}</Tab>
                                ))}


                            </TabList>
                        </Box>
                    </Tabs>


                    <Box
                        bg={"#ffff"}
                        borderRadius={"15px"}
                        boxShadow={"0px 7px 16px 4px #EAEAEA"}
                        p={5}
                        width={'910px'}
                        ml={'250px'}
                        mt={'100px'}

                    >
                        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                            <h1 fontSize="md">Adicionar vídeo</h1>
                            <Button onClick={saveInfo}>Salvar</Button>
                        </Box>
                        <Box mt={'20px'}
                            mb={'20px'}>
                            <Text>Adicione o link do vídeo</Text>
                            <Input placeholder={"Digite Aqui"} value={video} onChange={handleVideoChange}/>
                        </Box>

                        <Box>
                            <Text>Título</Text>
                            <Input placeholder={"Digite Aqui"} value={title} onChange={handleTitleChange} />
                        </Box>

                        <Stack margin="15px 0">
                            <Text fontSize="md">Descrição</Text>
                        </Stack>
                        <FormControl>
                            <JoditEditor

                                onBlur={handleDescriptionChange}
                                value={description}
                            />
                        </FormControl>
                    </Box>




                    {savedInfos.map((info, index) => (
                        <Box
                            key={index}
                            bg={"#ffff"}
                            borderRadius={"15px "}
                            boxShadow={"0px 7px 16px 4px #EAEAEA"}
                            p={5}
                            width={'910px'}
                            ml={'250px'}
                            mt={'20px'}
                        >
                            <Box display={'flex'}
                                alignItems={'center'}
                                justifyContent={'space-between'}
                                borderBottom={'1px'}
                                borderColor={'#D2D1D166'}>
                                <Text fontSize={'20px'}>Vídeo Provatis</Text>
                                <Text color={'#979797'} fontSize={'12px'}>00/00/0000 - 00:00h</Text>
                            </Box>
                            <Box
                                mt={'20px'}
                                display={'flex'}
                                flexDirection={'column'}
                                alignItems={'flex-start'}>
                                <Text
                                    p={1}
                                    borderRadius={'20px'}
                                    background={'black'}
                                    color={'white'} >
                                    Pontuação </Text>
                            </Box>
                            <Box mt={'20px'} mb={'15px'}>
                                <Text fontSize={'24px'} >{info.title}</Text>
                            </Box>
                            <Box>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: info.description
                                    }}
                                />

                            </Box>

                            <AspectRatio maxW='560px' ratio={1}>
                                <iframe
                                    src={info.video}
                                    allowFullScreen
                                />
                            </AspectRatio>


                            {/*
                    <Box display={'flex'} mt={'20px'} alignItems={'center'} gap={1}>
                        <Text fontSize={'16px'} color={'#888686'}>Essa dica foi útil?</Text>
                        <AiOutlineLike fontSize={'16px'} color={'#888686'} />
                        <Text fontSize={'16px'} color={'#888686'}>Sim</Text>
                        <AiOutlineDislike fontSize={'16px'} color={'#888686'} />
                        <Text fontSize={'16px'} color={'#888686'}>Não</Text>
                        
                    </Box>
                    */}
                        </Box>
                    ))}
                </>

            )}
        </>
    )
}