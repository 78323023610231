import React, { useContext } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Login from "./pages/Login/Login";
import Home from "./pages/Home/Home";
import Clients from "./pages/User/Client/Clients";
import Users from "./pages/User/Users";
import CreateUser from "./pages/User/CreateUser"
import Orders from "./pages/Order/Orders";
import CreateClient from "./pages/User/Client/CreateClient";
import CreateOrder from "./pages/Order/CreateOrder";
import Order from "./pages/Order/Order";
import { AuthContext } from "./contexts/Auth";
import HomeExpert from "./pages/Home/HomeExpert";
import OrderExpert from "./pages/Order/OrderExpert";
import UserScore from "./pages/Score/UserScore";
import MyPerformance from "./pages/Performance/MyPerformance";
import Prolab from "./pages/Prolab/Prolab";
import TeachingPills from "./components/Prolab/TeachingPills";
import Cadastro from "./pages/Cadastro/AtualizacaoCadastro"


function Router() {
  const Private = ({ children }) => {
    const { user, authenticated } = useContext(AuthContext);
    const token = localStorage.getItem("token");
    if (token === null || user === false) {
      return <Navigate to="/" />;
    }

    return children;
  };

  return (
    <Routes>
      <Route exact path="/" element={<Login />} />
      <Route
        path="/profile/expert/:slug"
        element={
          <Private>
            <MyPerformance />
          </Private>
        }
      />
      <Route
        path="/home"
        element={
          <Private>
            <Home />
          </Private>
        }
      />
      <Route
        path="/home/:slug"
        element={
          <Private>
            <HomeExpert />
          </Private>
        }
      />
      <Route
        path="/clientes"
        element={
          <Private>
            <Clients />
          </Private>
        }
      />
      <Route
        path="/adicionar/cliente"
        element={
          <Private>
            <CreateClient />
          </Private>
        }
      />
      <Route
        path="/usuarios"
        element={
          <Private>
            <Users />
          </Private>
        }
      />

      <Route
        path="adicionar/usuario"
        element={
          <Private>
            <CreateUser />
          </Private>
        }
      />
      <Route
      />
      <Route
        path="/servicos"
        element={
          <Private>
            <Orders />
          </Private>
        }
      />
      <Route
        path="/adicionar/servico"
        element={
          <Private>
            <CreateOrder />
          </Private>
        }
      />
      <Route
        path="/servico/:slug"
        element={
          <Private>
            <Order />
          </Private>
        }
      />
      <Route
        path="expert/servico/:slug"
        element={
          <Private>
            <OrderExpert />
          </Private>
        }
      />
      <Route
        path="/prolab"
        element={
          <Private>
            <Prolab />
          </Private>
        }
      />

       <Route
        path="/prolab/adm"
        element={
          <Private>
            <TeachingPills />
          </Private>
        }
      />

      <Route
        path="/teste"
        element={
          <Cadastro />

        }
      />
    </Routes>
  );
}

export default Router;
