
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import {
    Flex,
    FormControl,
    FormLabel,
    Input,
    Stack,
    Icon,
    Button,
    Checkbox,
    SimpleGrid,
    Image,
    Center,
    Box,
    Text

} from '@chakra-ui/react';
import Logo from "../../images/logo.png";
import BGLogin from "../../images/bg-login.png";
import { AuthContext } from "../../contexts/Auth";
import { TiSocialFacebook, TiSocialInstagram, TiSocialLinkedin, TiSocialYoutube } from "react-icons/ti";
const Login = () => {

    const { login, loading } = useContext(AuthContext);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();
        login(email, password);
    }
    return (
        <>
            <Stack maxH={'100vh'} direction={{ base: 'column', md: 'row' }} backgroundColor={'white'}>
                <Flex minH={'100vh'} flex={1} backgroundImage={BGLogin} alt={'Login Image'}
                    objectFit={'cover'} backgroundRepeat={'no-repeat'} justifyContent={'center'} alignItems={'center'}>
                    <Box>
                        <Center>
                            <Text fontWeight={600} fontSize={'86px'} color={'white'} lineHeight={'75px'}>Sua<br></br>área<br></br>Expert.</Text>
                        </Center>
                    </Box>


                </Flex>
                <Flex p={8} flex={1} align={'center'} justify={'center'}>
                    <Stack spacing={2} w={'full'} maxW={'md'}>
                        <Flex justifyContent={'center'} alignItems={'center'}>
                            <Box>
                                <Image src={Logo} maxWidth={'200px'}></Image>
                            </Box>
                        </Flex>

                        <Box>
                            <Text fontSize={18} fontWeight={400} paddingTop={2} paddingBottom={2}>Faça seu login na Provatis</Text>

                            <FormControl id="email" marginTop={5} marginBottom={4}>
                                <Input height={'64px'} borderRadius={50} placeholder={'Coloque seu email'} type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                            </FormControl>

                            <FormControl id="password">
                                <Input height={'64px'} borderRadius={50} placeholder={'Coloque sua senha'} type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                            </FormControl>

                            <Stack spacing={3}>

                                <Button height={'50px'} marginTop={5} borderRadius={50} bgColor={'#05070B'} type="submit" onClick={(e) => handleSubmit(e)} colorScheme={'green'} variant={'solid'}>
                                    Sign in
                                </Button>

                                <Stack align={'end'} color={'#04D194'} textDecoration={'underline'} fontSize={'14px'}>
                                    <Link >Esqueci a senha</Link>
                                </Stack>
                            </Stack>
                        </Box>
                        <Box display={'flex'} justifyContent={'center'}>
                            <SimpleGrid columns={4} spacing='40px' maxWidth={'80%'}  >
                                <Box height='60px' width={'60px'} display={'flex'} alignItems={'center'} justifyContent={'center'} fontSize={25} borderRadius={"50%"} border={'1px  solid #000'} > <TiSocialFacebook /></Box>
                                <Box height='60px' width={'60px'} display={'flex'} alignItems={'center'} justifyContent={'center'} fontSize={25} borderRadius={"50%"} border={'1px  solid #000'}> <TiSocialInstagram /></Box>
                                <Box height='60px' width={'60px'} display={'flex'} alignItems={'center'} justifyContent={'center'} fontSize={25} borderRadius={"50%"} border={'1px  solid #000'}> <TiSocialLinkedin /></Box>
                                <Box height='60px' width={'60px'} display={'flex'} alignItems={'center'} justifyContent={'center'} fontSize={25} borderRadius={"50%"} border={'1px  solid #000'}><TiSocialYoutube
                                /></Box>
                            </SimpleGrid>
                        </Box>


                        <Stack align={'center'} fontSize={'14px'} paddingTop={5} >
                            <Box>
                                Não é um expert <Text display={'inline'} color={'#04D194'} textDecoration={'underline'}> <Link >clique aqui</Link></Text>
                            </Box>
                        </Stack>

                    </Stack>
                </Flex>

            </Stack >
        </>
    );
}

export default Login;