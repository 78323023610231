import React, { useContext, useState, useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { format } from "date-fns";
import {
  Box,
  IconButton,
  Flex,
  FormControl,
  FormLabel,
  Select,
  Text,
  Grid,
  GridItem,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  useDisclosure,
  Button,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useToast,
} from "@chakra-ui/react";
import { OrderContext } from "../../contexts/Order";
import { UserContext } from "../../contexts/User";

export default function DetailsOrder() {
  const { getOrderInfo, approveOrderApi } = useContext(OrderContext);
  const { listExpertsApi, inviteExpertApi } = useContext(UserContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const [orderDetails, setOrderDetails] = useState();
  const [expertID, setExpertID] = useState();
  const [listExperts, setListExperts] = useState();
  const [openModalApprove, setOpenModalApprove] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleOpenModalApprove = (index) => {
    setSelectedRow(index);
    setOpenModalApprove(true);
  };

  const handleCloseModalApprove = () => {
    setOpenModalApprove(false);
  };
  async function getOrderDetails() {
    const orderID = localStorage.getItem("orderID");

    try {
      const orderInfoApi = await getOrderInfo(orderID);
      setOrderDetails(orderInfoApi.data?.[0]);
      console.log(orderInfoApi.data?.[0]);
    } catch (error) {
      console.log(error);
    }
  }

  async function getListExperts(id) {
    const params = {
      permission: id,
    };
    try {
      const listExpertsInfo = await listExpertsApi(params);
      setListExperts(listExpertsInfo.data?.[0]);
    } catch (error) {
      console.log(error);
      setListExperts(null);
    }
  }

  async function handleInviteExpert() {
    const orderID = localStorage.getItem("orderID");

    const data = {
      user_id: expertID,
      order_id: orderID,
    };
    try {
      const response = await inviteExpertApi(data);
      if (response.status === 200) {
        toast({
          title: "Expert convidado com Sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
      onClose();
    } catch (error) {
      toast({
        title: "Deu algo errado",
        status: "error",
        isClosable: true,
      });
      console.log(error);
    }
  }

  async function approveExpert(value, answer) {
    const orderID = localStorage.getItem("orderID");

    const data = {
      order_id: orderID,
      user_id: value.user_id,
      answer: answer,
      words_done: value.words_done,
    };

    try {
      const response = await approveOrderApi(data);
      if (response.status === 200) {
        toast({
          title: "Resposta Enviada",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
      setOpenModalApprove(false);
      getOrderDetails();
    } catch (error) {
      toast({
        title: "Deu algo errado",
        status: "error",
        isClosable: true,
      });
      console.log(error);
    }

    handleCloseModalApprove();
  }

  useEffect(() => {
    getOrderDetails();
  }, []);

  return (
    <>
      {orderDetails && (
        <>
          <Box
            bg={"#ffff"}
            borderRadius={"15px "}
            boxShadow={"0px 7px 16px 4px #EAEAEA"}
            p={5}
            ml={"180px"}
            maxWidth={"100%"}
          >
            <Flex pb={5} borderBottom={"1px solid #D9D9D9"}>
              <Box>Detalhes do serviços</Box>
            </Flex>
            <Grid
              paddingTop={5}
              templateColumns="repeat(2, 1fr)"
              borderBottom={"1px solid  #D9D9D9"}
              paddingBottom={5}
              marginBottom={5}
            >
              <GridItem>
                <Text color={"#748298"} fontSize={"14px"} fontWeight={"light"}>
                  Título
                </Text>

                <Text fontSize={"16px"}> {orderDetails.title}</Text>
              </GridItem>
            </Grid>

            <Grid
              templateColumns="repeat(4, 1fr)"
              borderBottom={"1px solid  #D9D9D9"}
              paddingBottom={5}
              marginBottom={5}
            >
              <GridItem>
                <Text color={"#748298"} fontSize={"14px"} fontWeight={"light"}>
                  ID
                </Text>

                <Text> {orderDetails.id}</Text>
              </GridItem>

              <GridItem>
                <Text color={"#748298"} fontSize={"14px"} fontWeight={"light"}>
                  Plano
                </Text>
                {orderDetails.details?.map((index) => (
                  <Text>{index.plan}</Text>
                ))}
              </GridItem>
              <GridItem>
                <Text color={"#748298"} fontSize={"14px"} fontWeight={"light"}>
                  Tipo de Documento
                </Text>
                <Text pl={3}>{orderDetails.type_document}</Text>
              </GridItem>

              <GridItem>
                <Text color={"#748298"} fontSize={"14px"} fontWeight={"light"}>
                  FastDelivery
                </Text>
                <Text pl={3}>
                  {orderDetails.fast_delivery === 0 ? "NÃO" : "SIM"}
                </Text>
              </GridItem>
            </Grid>

            <Grid
              templateColumns="repeat(2, 1fr)"
              borderBottom={
                orderDetails.description != null ? "1px solid #D9D9D9" : ""
              }
              paddingBottom={5}
              marginBottom={5}
            >
              <GridItem>
                <Text color={"#748298"} fontSize={"14px"} fontWeight={"light"}>
                  Idioma origem do documento
                </Text>

                <Text>
                  {orderDetails.language_origin === "br" ? "Português" : ""}
                </Text>
              </GridItem>
              <GridItem>
                <Text color={"#748298"} fontSize={"14px"} fontWeight={"light"}>
                  Idioma final do documento
                </Text>
                <Text pl={3}>
                  {orderDetails.language_final === "en" ? "Inglês" : ""}{" "}
                </Text>
              </GridItem>
            </Grid>
            {orderDetails.description != null ? (
              <Grid>
                <GridItem>
                  <Text color={"#748298"} pb={15}>
                    Descrição
                  </Text>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `<Text pl={3}>${orderDetails.description}</Text>`,
                    }}
                  />
                </GridItem>
              </Grid>
            ) : (
              ""
            )}
          </Box>

          <Box
            mt={5}
            bg={"#ffff"}
            borderRadius={"15px "}
            boxShadow={"0px 7px 16px 4px #EAEAEA"}
            p={5}
            mb={35}
            ml={"180px"}
            maxWidth={"100%"}
          >
            <Text borderBottom={"1px solid  #D9D9D9"} pb={5}>
              Valor e Prazo
            </Text>
            <Grid templateColumns="repeat(3, 1fr)">
              <GridItem pt={2}>
                <Text color={"#748298"} fontSize={"14px"} fontWeight={"light"}>
                  Valor do serviço
                </Text>

                <Text>
                  R${" "}
                  {orderDetails.price_estimated.toLocaleString("pt-br", {
                    minimumFractionDigits: 2,
                  })}
                </Text>
              </GridItem>
              <GridItem
                display={"flex"}
                alignItems={"center"}
                fontSize={"12px"}
                pt={5}
              >
                {orderDetails.details?.map((index) => (
                  <>
                    <span style={{ marginRight: "10px" }}>Data de entrega</span>
                    <Moment format="DD-MM-YYYY">{index.deadline}</Moment>
                  </>
                ))}
              </GridItem>
            </Grid>
          </Box>

          <Box
            mt={5}
            bg="#ffff"
            borderRadius="15px"
            boxShadow="0px 7px 16px 4px #EAEAEA"
            p={5}
            mb={35}
            ml={180}
          >
            <Flex
              justifyContent="space-between"
              borderBottom="1px solid #D9D9D9"
              pb={5}
            >
              <Box>
                <Text>Etapas</Text>
              </Box>
              <Box>
                <Button onClick={onOpen}>Convidar Expert</Button>
              </Box>
            </Flex>

            <Box overflowX="auto">
              <Table fontSize="12px">
                <Thead css={{ fontFamily: "Poppins", fontSize: "12px" }}>
                  <Tr>
                    <Th>Etapa</Th>
                    <Th>Expert</Th>
                    <Th>Status</Th>
                    <Th>Valor</Th>
                    <Th>Data Entrega</Th>
                    <Th>N. Palavras</Th>
                    <Th>Finalizado</Th>
                    <Th>Aprovado</Th>
                    <Th>Solicitado aprovação</Th>
                  </Tr>
                </Thead>
                <Tbody color="#888686" fontWeight={300} fontSize="10px">
                  {orderDetails?.historic?.map((index) => (
                    <Tr key={index.id}>
                      <Td>{index.permission}</Td>
                      <Td>{index.name}</Td>
                      <Td>{index.status}</Td>
                      <Td>
                        R${" "}
                        {index.value.toLocaleString("pt-br", {
                          minimumFractionDigits: 2,
                        })}
                      </Td>
                      <Td>{format(new Date(index.deadline), "dd/MM/yyyy")}</Td>
                      <Td>
                        {index.words_done === null ? 0 : index.words_done}
                      </Td>
                      <Td>{index.finished === 0 ? "Não" : "Finalizado"}</Td>
                      <Td>
                        {index.approved === 0
                          ? "Não"
                          : index.approved === 1
                          ? "Pendente"
                          : index.approved === 2
                          ? "Aprovado"
                          : ""}
                      </Td>
                      <Td>{index.finished_date}</Td>
                      <Td>
                        <Button
                          onClick={() => handleOpenModalApprove(index)}
                          fontSize={"12px"}
                        >
                          Aprovar Finalização
                        </Button>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
          </Box>
          <Box
            bg={"#ffff"}
            borderRadius={"15px "}
            boxShadow={"0px 7px 16px 4px #EAEAEA"}
            p={5}
            ml={"180px"}
            maxWidth={"100%"}
          >
            <Text borderBottom={"1px solid  #D9D9D9"} pb={5}>
              {" "}
              Documentos
            </Text>

            <Grid p={5} templateColumns="repeat(3, 1fr)">
              <GridItem>
                <Text color={"#748298"} pt={15} pb={5}>
                  Número de palavras
                </Text>

                <Text pl={3}>{orderDetails.words_number_original}</Text>
              </GridItem>
              <GridItem>
                <Text color={"#748298"} pt={15} pb={5}>
                  Número de referências
                </Text>

                <Text pl={3}>{orderDetails.total_reference}</Text>
              </GridItem>
              <GridItem>
                <Text color={"#748298"} pt={15} pb={5}>
                  Quantidade de figuras
                </Text>

                <Text pl={3}>{orderDetails.qty_figure}</Text>
              </GridItem>
            </Grid>
            <Box p={5}>
              <Text color={"#748298"} pt={15} pb={5}>
                Abstract
              </Text>
              <div
                dangerouslySetInnerHTML={{
                  __html: `<Text pl={3}>${orderDetails.abstract}</Text>`,
                }}
              />
            </Box>
          </Box>

          {/* * Modal Invite  Expert */}

          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Convidar Expert</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Flex display={"inline-flex"}>
                  <FormControl width={"48%"}>
                    <FormLabel>Etapa</FormLabel>
                    <Select
                      placeholder="Selecione a etapa"
                      onChange={(e) => getListExperts(e.target.value)}
                    >
                      <option value={1}>Tradutor</option>
                      <option value={2}>Revisor</option>
                      <option value={3}>Editor</option>
                    </Select>
                  </FormControl>
                  <FormControl ml={2} width={"48%"}>
                    <FormLabel>Usuarios</FormLabel>
                    <Select
                      placeholder="Selecione o expert"
                      onChange={(e) => setExpertID(e.target.value)}
                    >
                      {listExperts?.map((index) => (
                        <>
                          <option key={index.userID} value={index.userID}>
                            {index.name}
                          </option>
                        </>
                      ))}
                    </Select>
                  </FormControl>
                </Flex>
              </ModalBody>

              <ModalFooter>
                <Button colorScheme="blue" mr={3} onClick={handleInviteExpert}>
                  Convidar
                </Button>
                <Button variant="ghost" onClick={onClose}>
                  Fechar
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>

          {openModalApprove && selectedRow && (
            <Modal isOpen={openModalApprove} onClose={handleCloseModalApprove}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Modal Title</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Text>Etapa: {selectedRow.permission}</Text>
                  <Text>Expert: {selectedRow.name}</Text>
                  <Text>Quantidade de palavras: {selectedRow.words_done}</Text>
                </ModalBody>
                <ModalFooter>
                  <Button
                    colorScheme="blue"
                    mr={3}
                    onClick={() => approveExpert(selectedRow, 1)}
                  >
                    Aprovar
                  </Button>
                  <Button
                    colorScheme="red"
                    onClick={() => approveExpert(selectedRow, 0)}
                  >
                    {" "}
                    Não Aprovar
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          )}
        </>
      )}
    </>
  );
}
