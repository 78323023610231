import axios from "axios";



const api = axios.create({
  //baseURL: "http://localhost/provatis-api/public/api/"
  baseURL: "https://api.provatis.academy/public/api/"
});

api.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  //  alert(token);
  if (token) {
    axios.defaults.headers.Authorization = `Bearer ${token}`;
  }
  config.headers["Access-Control-Allow-Origin"] = "*";
  return config;
});

api.interceptors.response.use(

  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    console.log(error)

    if (error?.response?.status === 401) {

      window.location.pathname = "/home";

      if (window.location.pathname = "/home" && error?.response?.status === 401) {
        window.location.pathname = "/";
      }

      window.location.pathname = "/home";

      if (window.location.pathname = "/home" && error?.response?.status === 401) {
        window.location.pathname = "/";
      }

    }
    return Promise.reject(error);
  },
);



export default api;