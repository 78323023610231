import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import formatDate from "../../helpers/FormatDate";
import { AuthContext } from "../../contexts/Auth";
import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  Image,
  useColorModeValue,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  Menu,
  MenuButton,
} from "@chakra-ui/react";
import { getUserInfo } from "../../services/api";
import Logo from "../../images/logo.png";
import Profile from "../../images/avatar.jpg";
import {
  FiHome,
  FiSettings,
  FiMenu,
  FiClipboard,
  FiBell,
  FiUserCheck,
  FiChevronDown,
  FiUsers,
  FiChevronRight,
  FiUser,
} from "react-icons/fi";
import { TbHandRock } from "react-icons/tb";
import { HiOutlineLogout } from "react-icons/hi";
import { DiReact } from "react-icons/di";

export default function SimpleSidebar({ children }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} p="4">
        {children}
      </Box>
    </Box>
  );
}

const SidebarContent = ({ onClose, ...rest }) => {
  const [LinkItems, setLinkItems] = useState();

  function setLinksMenu() {
    const userInfo = JSON.parse(localStorage.getItem("user"));
    if (userInfo.role_id === 1) {
      const links = [
        { name: "Início", icon: FiHome, route: "/home" },
        { name: "Serviços", icon: FiClipboard, route: "/servicos" },
        { name: "Pagamentos", icon: FiUserCheck },
        { name: "Clientes", icon: FiUser, route: "/clientes" },
        {
          name: "Experts",
          icon: FiUsers,
          route: "/usuarios",
          borderBottom: "1px solid rgba(210, 209, 209, 0.4)",
        },
        { name: "Prolab", icon: FiSettings, route: "/prolab" },
        { name: "Settings", icon: FiSettings },
      ];
      setLinkItems(links);
    }

    if (userInfo.role_id === 2) {
      const links = [
        { name: "Notificações", icon: FiBell },
        { name: "Home", icon: FiHome, route: `/home/${userInfo.name}` },
        { name: "Serviços", icon: FiClipboard, route: "/servicos" },
        {
          name: "Meu Desenvolvimento",
          icon: FiUserCheck,
          route: `/profile/expert/${userInfo.name}`,
        },
        { name: "Settings", icon: FiSettings, route: "/clientes" },
      ];
      setLinkItems(links);
    }
  }

  useEffect(() => {
    setLinksMenu();
  }, []);

  const { logout } = useContext(AuthContext);

  function handleLogout() {
    logout();
  }
  return (
    <Box
      bg={useColorModeValue("white", "gray.900")}
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="5" justifyContent="space-between">
        <Image src={Logo} maxWidth={"180px"}></Image>

        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      <Box
        mb={4}
        borderBottom={"1px  solid rgba(210, 209, 209, 0.4)"}
        marginRight={5}
        marginLeft={5}
      ></Box>
      {LinkItems?.map((link) => (
        <Box
          key={link.name}
          mx={2}
          width={"92%"}
          borderBottom={link.borderBottom}
          paddingBottom={link.borderBottom != null ? "25px" : ""}
          marginBottom={link.borderBottom != null ? "25px" : ""}
          borderBottomWidth={link.borderBottom ? "1px" : "0"}
          marginRight={5}
          fontSize={15}
          fontWeight={"400"}
        >
          <Link to={link.route}>
            <NavItem key={link.name} icon={DiReact}>
              {link.name}
            </NavItem>
          </Link>
        </Box>
      ))}

      <Box
        position={"absolute"}
        bottom={"100px"}
        mx={2}
        width={"87%"}
        borderTop={"1px  solid rgba(210, 209, 209, 0.4)"}
        marginRight={5}
        marginLeft={5}
        fontSize={15}
        fontWeight={"400"}
      >
        <Link onClick={handleLogout}>
          <NavItem mt={5} fontWeight={"400"} icon={HiOutlineLogout}>
            Sair
          </NavItem>
        </Link>
      </Box>
    </Box>
  );
};

const NavItem = ({ icon, children, ...rest }) => {
  const today = formatDate(new Date(), "extense");

  return (
    <Flex
      align="center"
      p="2"
      mx="4"
      borderRadius={50}
      role="group"
      cursor="pointer"
      _hover={{
        bg: "#000",
        color: "white",
      }}
      {...rest}
    >
      {icon && (
        <Icon
          mr="4"
          fontSize="16"
          _groupHover={{
            color: "white",
          }}
          as={icon}
        />
      )}
      {children}
    </Flex>
  );
};

const MobileNav = ({ onOpen, ...rest }) => {
  const today = formatDate(new Date(), "extense");
  const userInfo = JSON.parse(localStorage.getItem("user"));
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue("white", "gray.900")}
      borderBottomWidth="1px"
      boxShadow={"0px 11px 20px -9px rgba(0,0,0,0.1)"}
      borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      justifyContent={{ base: "space-between", md: "flex" }}
      {...rest}
    >
      <Box display={{ sm: "none", md: "flex" }}>
        <Box display={"inline-flex"} alignItems={"center"}>
          <Icon color={"#00FF94"} fontSize={22} marginRight={2}>
            <FiChevronRight />
          </Icon>
          Hoje é {today}
          <Box
            backgroundColor={"#ceffea"}
            marginLeft={5}
            width={"35px"}
            height={"35px"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            borderRadius={"50%"}
          >
            <Icon color={"#00FF94"} fontSize={22}>
              <TbHandRock />
            </Icon>
          </Box>
        </Box>
      </Box>

      <IconButton
        display={{ base: "flex", md: "none" }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Image
        display={{ base: "flex", md: "none" }}
        src={Logo}
        maxWidth={"180px"}
      ></Image>

      <HStack spacing={{ base: "0", md: "6" }}>
        <IconButton
          size="lg"
          variant="ghost"
          aria-label="open menu"
          icon={<FiBell />}
        />
        <Flex alignItems={"center"}>
          <Menu>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{ boxShadow: "none" }}
            >
              <HStack>
                <Avatar size={"sm"} src={Profile} />
                <VStack
                  display={{ base: "none", md: "flex" }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2"
                >
                  <Text fontSize="sm">{userInfo.name}</Text>
                </VStack>
                {/** */}
              </HStack>
            </MenuButton>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
};
