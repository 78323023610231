import React from "react";
import api from "../services/api";

export const OrderContext = React.createContext({});

export const OrderProvider = ({ children }) => {
  const listOrdersApi = async (page) => {
    const token = localStorage.getItem("token");
    const response = await api.get("/list/orders", {
      params: {
        page: page, // Pass the page parameter as a query parameter
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  };

  const listOrdersExpertApi = async (page) => {
    const token = localStorage.getItem("token");
    const response = await api.get("/list/orders/expert", {
      params: {
        page: page, // Pass the page parameter as a query parameter
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  };

  const createOrderApi = async (data) => {
    const token = localStorage.getItem("token");
    const response = await api.post("/create/order", data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=utf-8",
      },
    });
    return response;
  };

  const listExpertsStepsApi = async (id) => {
    const token = localStorage.getItem("token");
    const response = await api.get(`/expert/steps/order/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  };

  const getOrderInfo = async (id) => {
    const token = localStorage.getItem("token");
    const response = await api.get(`/detail/order/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  };

  const getOrderFeedbackApi = async (id) => {
    const token = localStorage.getItem("token");
    const response = await api.get(`/list/feedbacks/order/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  };

  const showExpertOrderFeedbackApi = async (userID, orderID) => {
    const token = localStorage.getItem("token");
    const response = await api.get(
      `/show/feedback/expert/${userID}/order/${orderID}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  };

  const listOrderStepsApi = async (id) => {
    const token = localStorage.getItem("token");
    const response = await api.get(`/expert/steps/order/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  };

  const answerOrderApi = async (id, data) => {
    const token = localStorage.getItem("token");
    const response = await api.post(`/answer/invite/expert/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=utf-8",
      },
    });
    return response;
  };

  const sendApproveOrderApi = async (data) => {
    const token = localStorage.getItem("token");
    const response = await api.post(`/send/approve/order`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=utf-8",
      },
    });
    return response;
  };

  const approveOrderApi = async (data) => {
    const token = localStorage.getItem("token");
    const response = await api.post(`/approve/order`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=utf-8",
      },
    });
    return response;
  };

  const feedbackQuestionsApi = async (data) => {
    const token = localStorage.getItem("token");
    const response = await api.get(`/questions/feedbacks`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=utf-8",
      },
    });
    return response;
  };

  const feedbackCategoriesApi = async () => {
    const token = localStorage.getItem("token");
    const response = await api.get(`/list/categories`, {
      headers: {
        Authorization: `Bearer ${token}`,       
      },
    });
    return response;
  };

  const sendFeedbackApi = async (data) => {
    const token = localStorage.getItem("token");
    const response = await api.post(`/insert/feedback/user`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=utf-8",
      },
    });
    return response;
  };

  

  return (
    <OrderContext.Provider
      value={{
        listOrdersApi,
        createOrderApi,
        listExpertsStepsApi,
        getOrderInfo,
        listOrderStepsApi,
        answerOrderApi,
        listOrdersExpertApi,
        sendApproveOrderApi,
        approveOrderApi,
        feedbackQuestionsApi,
        feedbackCategoriesApi,
        sendFeedbackApi,
        getOrderFeedbackApi,
        showExpertOrderFeedbackApi,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};
