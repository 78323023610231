import React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";
import { AppProvider } from "./contexts";
import Router from "./routes";

function App (){
  return (
      <>
        <ChakraProvider>
            <AppProvider>
              <BrowserRouter>              
                <Router />
              </BrowserRouter>
            </AppProvider>
        </ChakraProvider>
      </>
  );
};


export default App;