import React, { useContext, useState, useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import { Link, useNavigate } from "react-router-dom";
import { format } from "date-fns";
import {
  Box,
  Container,
  Flex,
  Text,
  Spacer,
  useDisclosure,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { OrderContext } from "../../contexts/Order";

import { MdEditSquare, MdRemoveRedEye } from "react-icons/md";

export default function Orders() {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const { listOrdersApi, listOrdersExpertApi } = useContext(OrderContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [orders, setOrders] = useState([]);
  const [orderPreview, setOrderPreview] = useState();
  const [isPreviewing, setIsPreviewing] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);

  async function listOrders(page) {
    try {
      const ordersApi = await listOrdersApi(page);
      setOrders(ordersApi?.data?.[0]?.data || []);
      setLastPage(ordersApi?.data?.[0]?.last_page || 1);
    } catch (error) {
      console.log(error);
    }
  }

  async function listOrdersExpert(page) {
    try {
      const ordersApi = await listOrdersExpertApi(page);
      setOrders(ordersApi?.data?.[0]?.data || []);
      setLastPage(ordersApi?.data?.[0]?.last_page || 1);
    } catch (error) {
      console.log(error);
    }
  }
  function handleOrderPage(index) {
    localStorage.setItem("orderID", index.orderID);
    if (user.role_id === 1) {
      navigate(`/servico/${index.title}`);
    } else {
      navigate(`/expert/servico/${index.title}`);
    }
  }

  function previewOrder(index) {
    if (isPreviewing) {
      return;
    }

    setIsPreviewing(true);
    setOrderPreview(index);
    console.log(index);

    onOpen();

    setTimeout(() => {
      setIsPreviewing(false);
    }, 1000); // Aguardar 1 segundo antes de permitir uma nova chamada
  }
  useEffect(() => {
    if (user.role_id === 1) {
      listOrders(currentPage);
    } else if (user.role_id === 2) {
      listOrdersExpert(currentPage);
    }
  }, [currentPage]);

  function goToPreviousPage() {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  }

  function goToNextPage() {
    if (currentPage < lastPage) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  }

  return (
    <>
      <Navbar />

      <Container position={"absolute"} top={120} ml={270} minWidth={"78vw"}>
        <Box
          bg={"#ffff"}
          borderRadius={"15px"}
          boxShadow={"0px 7px 16px 4px #EAEAEA"}
        >
          <Flex display={"flex"} padding={5} alignItems="center">
            <Box>
              <Text fontSize={"24px"} fontWeight={"600"}>
                Serviços
              </Text>
            </Box>

            <Spacer />

            {user.role_id === 1 ? (
              <>
                <Box>
                  <Button
                    fontSize={"14px"}
                    bg={"#00FF94"}
                    _hover={{
                      backgroundColor: "#33BA9D",
                    }}
                  >
                    <Link to="/adicionar/servico">
                      {" "}
                      Adicionar novo serviço{" "}
                    </Link>
                  </Button>
                </Box>
              </>
            ) : (
              ""
            )}
          </Flex>
        </Box>

        <Box
          backgroundColor={"white"}
          mt={5}
          boxShadow={"0px 8px 16px -10px"}
          borderRadius={"8px"}
          marginBottom={5}
        >
          <TableContainer>
            <Table fontSize={"12px"}>
              <Thead css={{ fontFamily: "Poppins", fontSize: "12px" }}>
                <Tr>
                  <Th>ID</Th>
                  <Th>Titulo</Th>
                  <Th>Expert</Th>
                  <Th>Status</Th>
                  <Th>Etapa</Th>
                  <Th>Plano</Th>
                  <Th>Tipo de Documento</Th>
                  <Th>Prazo Final</Th>
                </Tr>
              </Thead>
              <Tbody color={"#888686"} fontWeight={"300"}>
                {orders?.map((index) => (
                  <Tr key={index.orderID}>
                    <Td>{index.orderID}</Td>
                    <Td>{index.title}</Td>
                    <Td>{index.name}</Td>
                    <Td>{index.status}</Td>
                    <Td>{index.step}</Td>
                    <Td>{index.plan}</Td>
                    <Td>{index.type_document}</Td>
                    <Td>{format(new Date(index.deadline), "dd/MM/yyyy")}</Td>
                    <Td>
                      <Box display={"inline-flex"} fontSize={"16px"}>
                        <Box
                          cursor={"pointer"}
                          color={"#115EFA"}
                          onClick={() => handleOrderPage(index)}
                        >
                          <MdEditSquare />
                        </Box>
                        <Box ml={2} color={"#00FF94"}>
                          <MdRemoveRedEye
                            onClick={() => previewOrder(index)}
                            cursor={"pointer"}
                          />
                        </Box>
                      </Box>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
        <Box>
          <Button
            onClick={() => goToPreviousPage(currentPage - 1)}
            disabled={currentPage === 1}
            fontSize={"12px"}
          >
            &laquo; Previous
          </Button>
          <Button
            ml={2}
            onClick={() => goToNextPage(currentPage + 1)}
            disabled={currentPage === lastPage}
            fontSize={"12px"}
          >
            Next &raquo;
          </Button>
        </Box>
      </Container>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Preview do Serviço</ModalHeader>
          <ModalCloseButton />
          {orderPreview && (
            <ModalBody>
              <Text fontSize={"10px"}>Titulo do Serviço</Text>
              <Text>{orderPreview.title}</Text>
              <Box display={"inline-flex"} mt={5}>
                <Box>
                  <Text fontSize={"14px"}>{orderPreview.status}</Text>
                </Box>
                <Box ml={5}>
                  <Text fontSize={"14px"}>
                    Prazo Final: {orderPreview.deadline}
                  </Text>
                </Box>
              </Box>
              <Box mt={5} fontSize={"14px"} display={"inline-flex"}>
                <Box ml={2}>
                  <Text>ID: {orderPreview.orderID}</Text>
                </Box>
                <Box ml={2}>
                  <Text>| Plano: {orderPreview.plan}</Text>
                </Box>
                <Box ml={2}>
                  <Text> | Etapa: {orderPreview.step}</Text>
                </Box>
              </Box>
            </ModalBody>
          )}

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
