import React, { useState, useEffect, useContext } from "react";
import Navbar from "../../components/Navbar/Navbar";
import {
  Box,
  Icon,
  Avatar,
  Container,
  Flex,
  Text,
  SimpleGrid,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { TbHandRock } from "react-icons/tb";
import { AiOutlinePieChart } from "react-icons/ai";
import { UserContext } from "../../contexts/User";
import Logo from "../../images/avatar.jpg";

export default function Performance() {
  const user = JSON.parse(localStorage.getItem("user"));
  const [expert, setExpert] = useState();
  const [id, setID] = useState();
  const [totalScore, setTotalScore] = useState(null);
  const [categoryScore, setCategoryScore] = useState(null);
  const { getExpertInfoApi } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);

  async function getExpertInfo(id) {
    try {
      const response = await getExpertInfoApi(id);
      console.log(response.data?.[0]);
      setExpert(response.data?.[0]);
      setTotalScore(response.data?.[0]?.totalAverage);
      setCategoryScore(response.data?.[0]?.categoriesAverage);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (user.role_id === 1) {
      setID(localStorage.getItem("expertID"));
    }
    if (user.role_id === 2) {
      setID(user.id);
    }
  }, [user.role_id]); // Add an empty dependency array to prevent multiple calls on re-renders

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      getExpertInfo(id)
        .then(() => setIsLoading(false))
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    }
  }, [id]);

  // Render loading state while data is being fetched
  if (isLoading) {
    return <p>Loading...</p>;
  }
  return (
    <>
      <Navbar />

      {expert && (
        <>
          <Container
            pb={8}
            position={"absolute"}
            top={120}
            ml={{ base: 0, md: 300 }}
            minWidth={{ base: 0, md: "75vw" }}
          >
            <Box boxShadow={"0px 8px 16px -10px"} borderRadius={"8px"}>
              <Box
                backgroundColor={"#00FF94"}
                minHeight={"100px"}
                borderRadius={"8px 8px 0 0"}
              >
                <Flex alignItems={"center"} flexDirection={"row"} w={"100%"}>
                  <Box>
                    <Avatar
                      position={"absolute"}
                      left={"45px"}
                      top={"40px"}
                      w={"100px"}
                      h={"100px"}
                      src={Logo}
                    />
                  </Box>
                  <Box
                    display={"flex"}
                    w={"100%"}
                    justifyContent={"space-between"}
                  >
                    <Box paddingLeft={"150px"} paddingTop={"20px"}>
                      <Text fontWeight={500} fontSize={"24px"}>
                        {expert.name}
                      </Text>
                      <Text fontWeight={300} fontSize={"12px"}>
                        {expert.created_at}
                      </Text>
                    </Box>
                    <Box paddingRight={"20px"} paddingTop={"25px"}>
                      <Box
                        backgroundColor={"white"}
                        marginLeft={5}
                        width={"35px"}
                        height={"35px"}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        borderRadius={"50%"}
                      >
                        <Icon color={"#00FF94"} fontSize={22}>
                          <TbHandRock />
                        </Icon>
                      </Box>
                    </Box>
                  </Box>
                </Flex>
              </Box>
              <Flex
                backgroundColor={"white"}
                minHeight={"80px"}
                borderRadius={" 0 0 8px 8px"}
              >
                {/**  <Box
                    display={"flex"}
                    alignItems={"center"}
                    marginBottom={"25px"}
                  >
                    <Box
                      backgroundColor={"#F4921D21"}
                      width={"30px"}
                      height={"30px"}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      borderRadius={"50%"}
                    >
                      <Icon color={"#f4921d"} fontSize={22}>
                        <TbHandRock />
                      </Icon>
                    </Box>

                    <Box display={"inline-flex"} marginLeft={2}>
                      <Text fontSize={"22px"} fontWeight={"600"}>
                        01
                      </Text>
                      <Text
                        fontSize={{ base: "10px", md: "12px" }}
                        lineHeight={1}
                        fontWeight={"300"}
                        marginLeft={2}
                      >
                        Serviços<br></br>
                        em andamento
                      </Text>
                    </Box>

                    <Box display={"flex"} alignItems={"center"}>
                      <Box
                        backgroundColor={"#04D19421"}
                        marginLeft={5}
                        width={"30px"}
                        height={"30px"}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        borderRadius={"50%"}
                      >
                        <Icon
                          color={"#00FF94"}
                          fontSize={22}
                          textAlign={"center"}
                        >
                          <TbHandRock />
                        </Icon>
                      </Box>
                      <Box display={"inline-flex"} marginLeft={2}>
                        <Text fontSize={"22px"} fontWeight={"600"}>
                          02
                        </Text>
                        <Text
                          fontSize={{ base: "10px", md: "12px" }}
                          lineHeight={1}
                          fontWeight={"300"}
                          marginLeft={2}
                        >
                          Serviços<br></br>
                          Concluídos
                        </Text>
                      </Box>
                    </Box>

                    <Box display={"flex"} alignItems={"center"}>
                      <Box
                        backgroundColor={"#FF370B21"}
                        marginLeft={5}
                        width={"30px"}
                        height={"30px"}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        borderRadius={"50%"}
                      >
                        <Icon
                          color={"#ff370b"}
                          fontSize={22}
                          textAlign={"center"}
                        >
                          <TbHandRock />
                        </Icon>
                      </Box>
                      <Box display={"inline-flex"} marginLeft={2}>
                        <Text fontSize={"22px"} fontWeight={"600"}>
                          02
                        </Text>
                        <Text
                          fontSize={{ base: "10px", md: "12px" }}
                          lineHeight={1}
                          fontWeight={"300"}
                          marginLeft={2}
                        >
                          Serviços<br></br>
                          Cancelados
                        </Text>
                      </Box>
                    </Box>
                  </Box> */}
                <Box w={"660px"} marginTop={"25px"} marginLeft={"150px"}>
                  <Box display={"flex"} marginBottom={"25px"}>
                    <Box marginRight={"25px"}>
                      <Text
                        textAlign={"center"}
                        backgroundColor={"#04D19414"}
                        borderRadius={"100px"}
                        width={"80px"}
                        p={"2px 0"}
                        color={"#33BA9D"}
                      >
                        {expert.infos?.permission_id === 1
                          ? "Tradutor"
                          : expert.infos?.permission_id === 2
                          ? "Revisor"
                          : expert.infos?.permission_id === 3
                          ? "Editor"
                          : " "}
                      </Text>
                      <Text fontWeight={300}>
                        Formação:{expert.infos?.profession}
                      </Text>
                    </Box>
                    <Box>
                      <Text fontWeight={300} fontSize={"12px"}>
                        {" "}
                        <b>Dados para contato:</b>
                        <br /> Tel:{expert.phone} <br /> {expert.email}{" "}
                        Formação: Fisioterapia
                      </Text>
                    </Box>
                    <Box>
                      <Text>Keywords:</Text>
                      <Box display={"flex"} flexWrap={"wrap"} gap={1}>
                        <Text
                          fontSize={"14px"}
                          fontWeight={600}
                          backgroundColor={"#04D19414"}
                          borderRadius={"100px"}
                          color={"#33BA9D"}
                        >
                          {expert.infos?.keywords}
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Flex>
            </Box>
            <Flex
              backgroundColor={"white"}
              borderRadius={"8px 8px"}
              boxShadow={"0px 8px 16px -10px"}
              marginTop={"10px"}
            >
              <Box w={"100%"} pb={8}>
                <Box
                  w={"100%"}
                  display={"flex"}
                  marginTop={"20px"}
                  marginBottom={"20px"}
                  paddingBottom={"20px"}
                  alignItems={"center"}
                  justifyContent={"space-around"}
                  borderBottom={"1px"}
                  borderColor={"#B1B1B1"}
                >
                  <AiOutlinePieChart color={"#00FF94"} fontSize={"24px"} />
                  <Text fontWeight={400} fontSize={"20px"}>
                    Desempenho nos serviços
                  </Text>
                  <Text fontWeight={400} fontSize={"16px"} color={"#979797"}>
                    Informações atualizadas com base no seu último serviço na
                    Provatis
                  </Text>
                </Box>

                {totalScore != null ? (
                  <>
                    <SimpleGrid columns={3} gap={6} marginBottom={"20px"}>
                      <GridItem marginLeft={"30px"}>
                        {" "}
                        <Box
                          display={"flex"}
                          backgroundColor={"#000000"}
                          w={"240px"}
                          h={"120px"}
                          borderRadius={"8px"}
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          <Box>
                            <Text
                              fontWeight={400}
                              fontSize={"16px"}
                              color={"#B1B1B1"}
                            >
                              Média total Absoluta
                            </Text>
                            <Text
                              fontWeight={700}
                              fontSize={"40px"}
                              color={"#FFFFFF"}
                            >
                              {totalScore?.average_total_absolute}
                            </Text>
                          </Box>
                        </Box>
                      </GridItem>
                      <GridItem marginLeft={"30px"}>
                        {" "}
                        <Box
                          p={9}
                          display={"flex"}
                          backgroundColor={"#000000"}
                          w={"240px"}
                          h={"120px"}
                          borderRadius={"8px"}
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          <Box>
                            <Text
                              fontWeight={400}
                              fontSize={"16px"}
                              color={"#B1B1B1"}
                            >
                              Média Absoluta últimos 10 serviços
                            </Text>
                            <Text
                              fontWeight={700}
                              fontSize={"40px"}
                              color={"#FFFFFF"}
                            >
                              {totalScore?.average_ten_orders_absolute}
                            </Text>
                          </Box>
                        </Box>
                      </GridItem>
                      <GridItem marginLeft={"30px"}>
                        {" "}
                        <Box
                          display={"flex"}
                          backgroundColor={"#000000"}
                          w={"240px"}
                          h={"120px"}
                          borderRadius={"8px"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          p={9}
                        >
                          <Box>
                            <Text
                              fontWeight={400}
                              fontSize={"16px"}
                              color={"#B1B1B1"}
                            >
                              Média Relativa últimos 10 serviços
                            </Text>
                            <Text
                              fontWeight={700}
                              fontSize={"40px"}
                              color={"#FFFFFF"}
                            >
                              {totalScore?.average_ten_orders_weight}
                            </Text>
                          </Box>
                        </Box>
                      </GridItem>
                    </SimpleGrid>
                    <Box>
                      {categoryScore != null ? (
                        <>
                          <Grid templateColumns="repeat(3, 1fr)" gap={6}>
                            {categoryScore?.map((index) => (
                              <GridItem marginLeft={"30px"}>
                                <Box
                                  key={index.category}
                                  display={"flex"}
                                  justifyContent={"center"}
                                  alignItems={"center"}
                                  backgroundColor={"white"}
                                  w={"240px"}
                                  h={"120px"}
                                  borderRadius={"8px"}
                                  border={"1px"}
                                  borderColor={"#B1B1B1"}
                                >
                                  <Box>
                                    <Text
                                      fontWeight={400}
                                      fontSize={"16px"}
                                      color={"#B1B1B1"}
                                    >
                                      {index.category}
                                    </Text>

                                    <Text fontWeight={400} fontSize={"14px"}>
                                      {" "}
                                      Média Absoluta:{" "}
                                      {index.averageCategory?.absolute_value}
                                    </Text>
                                    <Text fontWeight={400} fontSize={"14px"}>
                                      {" "}
                                      Média Relativa:{" "}
                                      {index.averageCategory?.weight_value}
                                    </Text>
                                  </Box>
                                </Box>
                              </GridItem>
                            ))}
                          </Grid>
                        </>
                      ) : (
                        " "
                      )}
                    </Box>
                  </>
                ) : (
                  <Box p={8}> Não há informações de pontuação</Box>
                )}

                {/*
                                <Box marginTop={"25px"} marginBottom={"50px"}>
                                    <TableContainer>
                                        <Table fontSize={"12px"}>
                                            <Thead css={{ fontFamily: "Poppins", fontSize: "12px" }}>
                                                <Tr>
                                                    <Th>ID </Th>
                                                    <Th>Titulo </Th>
                                                    <Th>Função </Th>
                                                    <Th>Modificações </Th>
                                                    <Th>Consistência </Th>
                                                    <Th>Pontuação </Th>
                                                    <Th>Abreviação </Th>
                                                    <Th>Conexão </Th>
                                                    <Th>Média Final </Th>
                                                </Tr>
                                            </Thead>
                                        </Table>
                                    </TableContainer>
                                </Box> 
                               */}
              </Box>
            </Flex>
          </Container>
        </>
      )}
    </>
  );
}
